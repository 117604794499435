import {EquipmentData} from '@hconnect/common'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {AllFilterOptions} from '../../common/utils/filterParameter'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {getEquipmentIdType} from '../../utils'

import {EquipmentSearchField} from './EquipmentSearchField'
import {MainEquipmentSearchField} from './MainEquipmentSearchField'

type EquipmentFiltersProps = {
  visibleFilters: string[]
  onChange: (filterOptions: AllFilterOptions) => void
  settings: AllFilterOptions
  removeFilter: (filterName: string) => void
}

export const EquipmentFilters: FC<EquipmentFiltersProps> = ({
  visibleFilters,
  onChange,
  settings,
  removeFilter
}) => {
  const {t} = useTranslation()
  const shouldShowExtendedEquipmentId = useFeatureFlag('extendedEquipmentIds')
  const equipmentIdType = getEquipmentIdType(shouldShowExtendedEquipmentId)

  return (
    <>
      {visibleFilters.includes('equipment') && (
        <EquipmentSearchField
          data-test-id={'events-filter-bar-equipment'}
          onChange={(next: EquipmentData | undefined) => {
            onChange({...settings, equipment: next})
          }}
          value={settings.equipment}
          removeFilter={removeFilter}
          idType={equipmentIdType}
        />
      )}
      {visibleFilters.includes('mainEquipment') && (
        <MainEquipmentSearchField
          data-test-id={'events-filter-bar-mainEquipment'}
          onChange={(next: EquipmentData | undefined) => {
            onChange({...settings, mainEquipment: next})
          }}
          value={settings.mainEquipment}
          removeFilter={removeFilter}
          label={t('shiftEvent.label.mainEquipment')}
          idType={equipmentIdType}
        />
      )}
      {visibleFilters.includes('functionalEquipment') && (
        <MainEquipmentSearchField
          data-test-id={'events-filter-bar-functionalEquipment'}
          onChange={(next: EquipmentData | undefined) => {
            onChange({...settings, functionalEquipment: next})
          }}
          value={settings.functionalEquipment}
          removeFilter={removeFilter}
          label={t('shiftEvent.label.functionalEquipment')}
        />
      )}
    </>
  )
}
