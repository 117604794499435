import {trackEvent, TrackEventData} from '@hconnect/common/logging/Analytics'
import {Category, EventType} from '@hconnect/common/types'
import {isObject} from 'lodash'
import {useCallback} from 'react'

import {Page} from '../common/utils/filterParameter'

import {useGlobalContext} from './useGlobalContext'

type BaseAnalyticsEvent =
  | {
      name: 'onSapPmNotificationFeature'
      data: {timezone: string}
    }
  | {
      name: 'onErrorPageTracking'
      data: {errorCode?: string; errorMessage?: string}
    }
  | {
      name: 'onCategoryStoppageCodeEvent'
      data: {stoppageCode?: string; eventType: EventType; category?: Category}
    }
  | {
      name: 'onCreationStartEvent'
      data: {uuid: string}
    }
  | {
      name: 'onCreationFinishEvent'
      data: {uuid: string}
    }
  | {
      name: 'userReportsAStoppageFromRuntimeGraph'
      data: {stoppageId: string}
    }
  | {
      name: 'userMovesPage'
      data: {pageFrom: string; pageTo: string}
    }
  | {
      name: 'userAdjustsFilter'
      data: {filterPage: Page; filterName: string}
    }
  | {
      name: 'userDownloadsShiftSummaryPDF'
    }
  | {
      name: 'userDownloadsEventsList'
    }
  | {
      name: 'userAddsInstruction'
      data: {instructionId: string}
    }
  | {
      name: 'userEditsInstruction'
      data: {instructionId: string}
    }
  | {
      name: 'userDeletesInstruction'
      data: {instructionId: string}
    }
  | {
      name: 'userOpensInstructionDetails'
      data: {instructionId: string}
    }
  | {
      name: 'userClosesInstructionDetails'
      data: {instructionId: string}
    }

interface CommonAnalyticsData {
  data: {
    plantId?: string
    userId?: string
  }
}

type AnalyticsEvent = BaseAnalyticsEvent & CommonAnalyticsData

export const trackAnalyticsEvent = <T extends AnalyticsEvent['name']>(
  eventName: T,
  data: TrackEventData
) => trackEvent(eventName, {product: 'cockpit', ...data})

export const useTrackAnalyticsEvent = <V>() => {
  const {
    user: {userId},
    config: {plantId, country}
  } = useGlobalContext()

  return useCallback(
    <T extends AnalyticsEvent['name']>(
      eventName: T,
      data: Extract<V, {name: T}> extends {data: infer D} ? D : Record<string, never>
    ) => {
      if (plantId) {
        trackAnalyticsEvent(eventName, {
          timestamp: new Date().toISOString(),
          userId,
          plantId,
          country,
          ...(isObject(data) ? data : {})
        })
      }
    },
    [plantId, userId, country]
  )
}
