import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {FlexPage} from '@hconnect/common/components/FlexPage'
import {
  noSideCard,
  sideCardSize,
  tableWithOutSideCard,
  tableWithSideCard,
  withSideCard
} from '@hconnect/common/consts'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {useNotification} from '@hconnect/uikit'
import {CardBox, Column} from '@hconnect/uikit/src/lib2'
import {Add as AddIcon} from '@mui/icons-material'
import {Box, Button, Grid, Theme, useMediaQuery} from '@mui/material'
import {TFunction} from 'i18next'
import React, {FC, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {EventProcessStageTitle} from '../../components/common/EventProcessStageTitle'
import {DocumentCreate} from '../../components/documents/DocumentCreate'
import {DocumentDetails} from '../../components/documents/DocumentDetails'
import {DocumentEdit} from '../../components/documents/DocumentEdit'
import {DocumentLink} from '../../components/documents/DocumentLink'
import {DocumentStatus} from '../../components/documents/DocumentStatus'
import {useInstructionsPageLastVisited} from '../../hooks/useInstructionsPageLastVisited'
import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {Instruction} from '../../types/documents.types'
import {ShiftEvent} from '../../types/shiftHandover.types'
import {ColumnsMap} from '../../types/table.types'

import {DocumentDelete} from './DocumentDelete'
import {DocumentsTable} from './DocumentsTable'

const generateTitleColumn = (t: TFunction): Column<Instruction> => ({
  key: 'name',
  label: t('documents.label.documentLink'),
  sortable: true,
  customTemplate: (rowData) => <DocumentLink document={rowData} />
})
const generateStatusColumn = (t: TFunction): Column<Instruction> => ({
  key: 'status',
  label: t('documents.label.status'),
  sortable: true,
  customTemplate: (rowData) => <DocumentStatus document={rowData} />
})
const generateCategoryColumn = (t: TFunction): Column<Instruction> => ({
  key: 'category',
  label: t('documents.label.category'),
  sortable: true,
  customTemplate: ({category}) => (category ? t(`shiftEvent.category.${category}`) : '-')
})
const generateProcessStageColumn = (t: TFunction): Column<Instruction> => ({
  key: 'processStage',
  label: t('documents.label.processStage'),
  sortable: true,
  customTemplate: ({processStage}) => <EventProcessStageTitle stage={processStage} />
})
const generateColumnsMap = (t: TFunction): ColumnsMap<Instruction> => {
  return {
    XS: [generateTitleColumn(t)],
    S: [generateTitleColumn(t), generateStatusColumn(t)],
    M: [generateTitleColumn(t), generateProcessStageColumn(t), generateStatusColumn(t)],
    L: [
      generateTitleColumn(t),
      generateProcessStageColumn(t),
      generateCategoryColumn(t),
      generateStatusColumn(t)
    ],
    XL: [
      generateTitleColumn(t),
      generateProcessStageColumn(t),
      generateCategoryColumn(t),
      generateStatusColumn(t)
    ]
  }
}

type DocumentsViewProps = {
  isLoading: boolean
  viewMode: ViewMode<ShiftEvent>
  setViewMode: (mode: ViewMode<ShiftEvent>) => void
  documents?: Instruction[]
}

export const DocumentsView: FC<DocumentsViewProps> = ({
  isLoading,
  documents,
  viewMode,
  setViewMode
}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const trackAnalyticsEvent = useTrackAnalyticsEvent()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false)

  const {setLastVisited} = useInstructionsPageLastVisited()

  const breakPoint = useWidth()
  const isSideCardShown = viewMode.mode !== 'tableOnlyMode'
  const tableSize = (isSideCardShown ? withSideCard : noSideCard).get(breakPoint) ?? 'S'
  const columns = useMemo<ColumnsMap<Instruction>>(() => generateColumnsMap(t), [t])

  const onRowClick = useCallback(
    ({id}: Instruction) => {
      setViewMode({mode: 'detailsMode', itemId: id})
      trackAnalyticsEvent('userOpensInstructionDetails', {instructionId: id})
    },
    [setViewMode, trackAnalyticsEvent]
  )

  const onDeleteSuccess = useCallback(() => {
    setViewMode({mode: 'tableOnlyMode'})
    setDeleteDialog(false)
    notify('success', t('documents.action.deleteSuccess'))
  }, [notify, setViewMode, t])

  const isTableShown = !isSideCardShown || !isMobile
  const selectedDocument =
    viewMode.mode === 'detailsMode' || viewMode.mode === 'editMode'
      ? (documents || []).find((document) => document.id === viewMode.itemId)
      : undefined

  const closeSideCard = () => {
    if (viewMode.mode === 'editMode') {
      setViewMode({mode: 'detailsMode', itemId: viewMode.itemId})
    } else {
      setViewMode({mode: 'tableOnlyMode'})
    }
  }

  const onSuccessSubmit = (itemId: string) => {
    setViewMode({mode: 'detailsMode', itemId})
    notify('success', t('documents.label.createSuccess'))
    setLastVisited()
  }

  const onEdit = (id: string) => {
    setViewMode({mode: 'editMode', itemId: id})
    setLastVisited()
  }

  const onSuccessEdit = () => {
    closeSideCard()
    notify('success', t('documents.label.editSuccess'))
    setLastVisited()
  }
  const getCardContent = () => {
    if (viewMode.mode === 'detailsMode' && selectedDocument) {
      return (
        <DocumentDetails
          document={selectedDocument}
          onClose={closeSideCard}
          onEdit={onEdit}
          onDelete={() => setDeleteDialog(true)}
        />
      )
    }

    if (viewMode.mode === 'createMode') {
      return <DocumentCreate doClose={closeSideCard} onSuccessSubmit={onSuccessSubmit} />
    }

    if (viewMode.mode === 'editMode' && selectedDocument) {
      return (
        <DocumentEdit
          document={selectedDocument}
          doClose={closeSideCard}
          onSuccessSubmit={onSuccessEdit}
        />
      )
    }
  }

  return (
    <FlexPage title={t('documents.pageName')} appName="Cockpit">
      <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
        {isTableShown && (
          <Grid
            item
            height={'100%'}
            {...(isSideCardShown ? tableWithSideCard : tableWithOutSideCard)}
          >
            <CardBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <Box display="flex" justifyContent="flex-end" mb={1}>
                <Button
                  variant="text"
                  data-test-id="document-create"
                  onClick={() => {
                    setViewMode({mode: 'createMode'})
                  }}
                  startIcon={<AddIcon />}
                >
                  {t('documents.action.create')}
                </Button>
              </Box>
              <DocumentsTable
                documents={documents}
                isLoading={isLoading}
                columns={columns[tableSize]}
                onRowClick={onRowClick}
                selectedDocumentId={selectedDocument?.id}
              />
            </CardBox>
          </Grid>
        )}

        {isSideCardShown && (viewMode.mode as string) !== 'tableOnlyMode' && (
          <Grid
            item
            {...sideCardSize}
            sx={{
              height: '100%',
              overflow: 'hidden'
            }}
          >
            {getCardContent()}
          </Grid>
        )}
      </Grid>

      {!!(showDeleteDialog && selectedDocument) && (
        <DocumentDelete
          onSuccess={onDeleteSuccess}
          setDialogOpen={setDeleteDialog}
          instruction={selectedDocument}
          showDialog={true}
        />
      )}

      <p data-test-id="page-document-mode-indicator" style={{display: 'none'}}>
        {/* shall be used in cypress tests to validate the mode this page is in */}
        {viewMode.mode}
      </p>
    </FlexPage>
  )
}
