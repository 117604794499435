import {DateRange} from '@hconnect/common/types'
import {QuickSelectSlots} from '@hconnect/common/utils'
import {useMemo} from 'react'
import {SetURLSearchParams, useSearchParams} from 'react-router-dom'
import {useLocalStorage} from 'react-use'

import {FilterOptions, urlToFilterSettings} from '../common/utils/filterParameter'
import {pickKeysFromSearchParams} from '../utils'

import {useConfig} from './useConfig'
import {EVENT_FILTERS} from './useEventsFilter'

const KEY_TIME_RANGE = 'timeRange'
const KEYS = [KEY_TIME_RANGE]

const getTimeRange = (
  filters: string,
  timezone: string
): DateRange | QuickSelectSlots | undefined => {
  const filterOptions: FilterOptions = urlToFilterSettings(new URLSearchParams(filters), timezone)
  return filterOptions[KEY_TIME_RANGE]
}

export const useTimeRange = (): DateRange | QuickSelectSlots | undefined => {
  const {timezone, defaultTimeRange} = useConfig()

  const [storageData] = useLocalStorage<string>(EVENT_FILTERS)
  const [params]: [URLSearchParams, SetURLSearchParams] = useSearchParams()

  return useMemo<DateRange | QuickSelectSlots>(() => {
    const timeRangeQueryParam =
      pickKeysFromSearchParams(params, KEYS).toString() || storageData || ''
    return getTimeRange(timeRangeQueryParam, timezone) || defaultTimeRange
  }, [defaultTimeRange, params, storageData, timezone])
}
