import {
  CategoryDropDown,
  EventDescriptionBox,
  PriorityDropDown,
  ProcessStageAutocomplete,
  ProcessStageDropDown
} from '@hconnect/common/components/shiftEventFormFields'
import {EventHighlight} from '@hconnect/common/components/shiftEventFormFields/EventHighlight'
import {
  REGULAR_SIZE,
  INPUT_FORM_FIELD_SX,
  MAX_DESCRIPTION_CHARACTER_LIMIT
} from '@hconnect/common/consts'
import {EquipmentIdType, EventType, Priority, User} from '@hconnect/common/types'
import {removeMarkdownTags} from '@hconnect/common/utils'
import {
  shouldNotBeEmpty,
  shouldNotBeEmptyString,
  shouldNotBeEmptyStringWithMaxLength,
  ValidatorConfig
} from '@hconnect/common/validators'
import {Grid, TextField, Box} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useGlobalContext} from '../../hooks/useGlobalContext'
import {
  BaseEvent,
  ParameterChange,
  ParameterChangeData,
  Schedule,
  ShiftEvent,
  ShiftEventAndUpdateEvent
} from '../../types/shiftHandover.types'
import {maxStringLengthValidator} from '../../validators'
import {
  approversValidator,
  commonFormScheduleEndValidator
} from '../../validators/shiftEvent.validators'
import {ApproversField} from '../eventProperties/ApproversField'
import {EquipmentSearchContainer} from '../eventProperties/EquipmentSearchContainer'
import {EventInfo} from '../eventProperties/EventInfo'
import {EventTypeDropdownBox} from '../eventProperties/EventTypeDropdownBox'
import {ParameterChangeFunctionDropdown} from '../eventProperties/ParameterChangeFunctionDropdown'
import {ParameterChangeStatusDropDown} from '../eventProperties/ParameterChangeStatusDropDown'
import {ScheduleSwitch} from '../eventProperties/ScheduleSwitch'

type TypeOfParameterChange = ParameterChange

type PossibleErrors =
  | keyof TypeOfParameterChange
  | 'equipmentNumber'
  | 'parameterChange.approvers'
  | 'parameterChange.currentValue'
  | 'parameterChange.newValue'

type ParameterChangeFormProps = {
  item: Partial<TypeOfParameterChange>
  update: <K extends keyof TypeOfParameterChange>(
    key: K,
    value: TypeOfParameterChange[K] | EventType
  ) => void
  updateMultiPart: (delta) => void
  validationError: Map<PossibleErrors, string>
  originalApprovers?: User[]
  equipmentIdType?: EquipmentIdType
  isEditMode: boolean
  children: React.ReactNode
  autocompleteProcessStage?: boolean
}

export const parameterChangeValidatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent> = new Map([
  ['category', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  [
    'description',
    shouldNotBeEmptyStringWithMaxLength<ShiftEventAndUpdateEvent>(
      MAX_DESCRIPTION_CHARACTER_LIMIT,
      removeMarkdownTags
    )
  ],
  ['status', shouldNotBeEmpty<ShiftEventAndUpdateEvent>()],
  ['title', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()],
  ['schedule', commonFormScheduleEndValidator],
  ['parameterChange.approvers', approversValidator],
  ['parameterChange.currentValue', maxStringLengthValidator<ShiftEventAndUpdateEvent>()],
  ['parameterChange.newValue', maxStringLengthValidator<ShiftEventAndUpdateEvent>()]
])

export const parameterChangeDefaultObject = (): Partial<ShiftEvent> => ({
  description: '',
  eventType: 'parameterChange',
  status: 'pending',
  title: '',
  parameterChange: {
    currentValue: '',
    newValue: '',
    needsApproval: false
  }
})

export const ParameterChangeForm: React.FC<ParameterChangeFormProps> = ({
  children,
  item,
  update,
  updateMultiPart,
  validationError,
  originalApprovers,
  isEditMode,
  equipmentIdType,
  autocompleteProcessStage
}) => {
  const {t} = useTranslation()
  const {user, config} = useGlobalContext()

  const getErrorText = (key: PossibleErrors) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  const setParameterChangeData = (value: Partial<ParameterChangeData>) => {
    updateMultiPart({
      parameterChange: {
        ...item.parameterChange,
        ...value
      }
    })
  }

  const disableField = useMemo(() => {
    const isOwner = (item as ShiftEvent)?.createdBy?.id === user.userId
    const isApprover = !!item.parameterChange?.approvers?.find(({id}) => id === user.userId)

    return isEditMode && item.parameterChange?.needsApproval && !isOwner && !isApprover
  }, [item, user.userId, isEditMode])

  return (
    <>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          data-test-id="event-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t('shiftEvent.label.title')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            update('title', event.target.value)
          }
          required
          value={item.title}
          variant="filled"
          InputProps={INPUT_FORM_FIELD_SX}
          disabled={disableField}
        />

        <EventHighlight
          title={t('eventsTable.action.highlight')}
          value={item.isHighlighted}
          onChange={(value) => update('isHighlighted', value)}
          disabled={disableField}
        />
      </Box>

      {'created' in item && <EventInfo item={item as BaseEvent} />}

      <Box my={1}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item {...REGULAR_SIZE}>
            <EventTypeDropdownBox
              item={item as BaseEvent}
              value={'parameterChange'}
              updateMultiPart={updateMultiPart}
            />
          </Grid>
          <Grid item {...REGULAR_SIZE}>
            <ParameterChangeStatusDropDown
              eventData={item as ShiftEvent}
              data-test-id="event-form-status"
              errorText={getErrorText('status')}
              onChange={(value) => update('status', value)}
              isEditMode={isEditMode}
              disabled={disableField}
            />
          </Grid>

          <Grid item xs={12}>
            <EquipmentSearchContainer
              data-test-id="event-form-equipmentNumber"
              errorText={getErrorText('equipmentNumber')}
              updateMultiPart={updateMultiPart}
              value={item as BaseEvent}
              disabled={disableField}
              equipmentIdType={equipmentIdType}
            />
          </Grid>

          {autocompleteProcessStage ? (
            <Grid item xs={12}>
              <ProcessStageAutocomplete
                plantId={config.plantId}
                data-test-id="event-form-processStage"
                errorText={getErrorText('processStage')}
                onChange={(value) => update('processStage', value)}
                value={item.processStage}
              />
            </Grid>
          ) : (
            <Grid item {...REGULAR_SIZE}>
              <ProcessStageDropDown
                plantId={config.plantId}
                data-test-id="event-form-processStage"
                errorText={getErrorText('processStage')}
                onChange={(value) => update('processStage', value)}
                value={item.processStage}
                disabled={disableField}
              />
            </Grid>
          )}
          <Grid item {...REGULAR_SIZE}>
            <CategoryDropDown
              data-test-id="event-form-category"
              errorText={getErrorText('category')}
              onChange={(value) => update('category', value)}
              required
              value={item.category}
              disabled={disableField}
            />
          </Grid>

          <Grid item {...REGULAR_SIZE}>
            <PriorityDropDown
              data-test-id="event-form-priority"
              errorText={getErrorText('priority')}
              onChange={(value) => update('priority', value)}
              value={item.priority as Priority}
              disabled={disableField}
            />
          </Grid>

          <Grid item {...REGULAR_SIZE}>
            <ParameterChangeFunctionDropdown
              data-test-id="event-form-functionId"
              onChange={(value) => {
                setParameterChangeData({functionId: value})
              }}
              value={item.parameterChange?.functionId}
              disabled={disableField}
            />
          </Grid>

          <ScheduleSwitch
            disabled={disableField}
            item={item}
            onChange={(value: Schedule | undefined) => update('schedule', value)}
            errorText={getErrorText('schedule')}
          />

          <Grid item xs={12}>
            <TextField
              required
              data-test-id="event-form-parameter-current"
              error={validationError.has('parameterChange.currentValue')}
              fullWidth={true}
              helperText={getErrorText('parameterChange.currentValue')}
              label={t('shiftEvent.label.currentValue')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setParameterChangeData({currentValue: event.target.value})
              }}
              value={item.parameterChange?.currentValue}
              variant="filled"
              InputProps={INPUT_FORM_FIELD_SX}
              disabled={disableField}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              data-test-id="event-form-parameter-new"
              error={validationError.has('parameterChange.newValue')}
              fullWidth={true}
              helperText={getErrorText('parameterChange.newValue')}
              label={t('shiftEvent.label.newValue')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setParameterChangeData({newValue: event.target.value})
              }
              value={item.parameterChange?.newValue}
              variant="filled"
              InputProps={INPUT_FORM_FIELD_SX}
              disabled={disableField}
            />
          </Grid>

          <ApproversField
            toggleApprovers={(needsApproval) => {
              setParameterChangeData({
                needsApproval,
                approvers: needsApproval ? originalApprovers : undefined
              })
              update('status', needsApproval ? 'waitingForApproval' : 'pending')
            }}
            onChange={(approvers) => {
              setParameterChangeData({approvers})
            }}
            errorText={getErrorText('parameterChange.approvers')}
            approvers={item.parameterChange?.approvers || []}
            needsApproval={!!item.parameterChange?.needsApproval}
            disabled={disableField}
          />

          <Grid item xs={12}>
            <EventDescriptionBox
              current={item.description || ''}
              errorCode={validationError.get('description')}
              onChange={(next) => update('description', next)}
              required
              disabled={disableField}
            />
          </Grid>
        </Grid>
      </Box>
      {children}
    </>
  )
}
