import {DateRange, FilterTimeRange} from '@hconnect/common/types'
import {getQuickSelectFilter, QUICK_SELECT_SLOTS, QuickSelectSlots} from '@hconnect/common/utils'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'

import {isSingleShiftRange} from '../common/utils/shift'
import {TimeRange} from '../types/timeAndDate.types'
import {toTimeRange} from '../utils/timeRangeHelper'

import {generateTimeRange} from './api/useEventsSearch'
import {useConfig} from './useConfig'
import {useTimeRange} from './useTimeRange'

export const useFilterDatePicker = () => {
  const {defaultTimeRange, shifts, plantNow, timezone} = useConfig()
  const filterTimeRange = useTimeRange()
  const quickSelectSlot: QuickSelectSlots =
    (QUICK_SELECT_SLOTS.includes(filterTimeRange as QuickSelectSlots) &&
      (filterTimeRange as QuickSelectSlots)) ||
    defaultTimeRange
  const [timeRange, setTimeRange] = useState<TimeRange>(
    toTimeRange(getQuickSelectFilter(quickSelectSlot, shifts, plantNow()))
  )
  const [isSingleShift, setShiftRange] = useState<boolean>(
    isSingleShiftRange(
      moment.utc(timeRange.startDate).tz(timezone),
      moment.utc(timeRange.endDate).tz(timezone),
      shifts
    )
  )
  const dateRange = useMemo<DateRange>(
    () => ({
      startDate: moment.utc(timeRange.startDate).tz(timezone),
      endDate: moment.utc(timeRange.endDate).tz(timezone)
    }),
    [timeRange.endDate, timeRange.startDate, timezone]
  )

  const getDateRange = (): DateRange => {
    const [startDate, endDate] = getQuickSelectFilter(defaultTimeRange, shifts, plantNow())
    return {startDate, endDate}
  }

  useEffect(() => {
    const isSingleShift = isSingleShiftRange(
      moment.utc(timeRange.startDate).tz(timezone),
      moment.utc(timeRange.endDate).tz(timezone),
      shifts
    )
    setShiftRange(isSingleShift)
  }, [timeRange, shifts, timezone])

  useEffect(() => {
    const filterRange: FilterTimeRange = generateTimeRange(filterTimeRange, shifts, plantNow)
    if (filterRange.startDate && filterRange.endDate) {
      const range: TimeRange = {
        startDate: filterRange.startDate,
        endDate: filterRange.endDate
      }
      setTimeRange(range)
    }
  }, [filterTimeRange, timezone, shifts, plantNow])

  return {
    timeRange,
    dateRange,
    isSingleShift,
    getDateRange,
    quickSelectSlot
  }
}
