import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {OrderType} from '../../../types'
import {useApi} from '../useApi'

const QueryKey = 'order-types'

export const useOrderTypes = (options?: UseQueryOptions<OrderType[], AxiosError>) => {
  const {axiosInstance} = useApi()
  const path = '/work-orders/order-types'

  return useQuery<OrderType[], AxiosError>(
    [QueryKey],
    async () => {
      const {data} = await axiosInstance.get<OrderType[]>(path)
      return data
    },
    options
  )
}
