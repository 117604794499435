import {EventAttachment, EventAttachmentsDisplay} from '@hconnect/common/components/attachments'
import {ContentBox} from '@hconnect/common/components/ContentBox'
import {SideCardModes} from '@hconnect/common/components/eventsList/types'
import {EventTaskChecklists} from '@hconnect/common/components/hrocChecklists'
import {Tag} from '@hconnect/common/components/Tag'
import {MAX_ALLOWED_ATTACHMENTS, SIDE_CARD_MIN_HEIGHT_IN_PX} from '@hconnect/common/consts'
import {headlineSx, imageBoxSx} from '@hconnect/common/styles/images'
import {Stoppage} from '@hconnect/common/types'
import {getUniqueAttachments} from '@hconnect/common/utils'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {Box, Divider} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router'

import {isTask} from '../../common/utils/eventType'
import {useEvent} from '../../hooks/api/useEvents'
import {useTaskChecklists} from '../../hooks/api/useTaskChecklists'
import {useConfig} from '../../hooks/useConfig'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {usePermission} from '../../hooks/usePermission'
import {EventDetails} from '../../layouts/EventDetails'
import {EventNotFound} from '../../layouts/EventNotFound'
import {ShiftEvent} from '../../types/shiftHandover.types'
import {Comments} from '../shiftEvents/Comments'
import {EventCreate} from '../shiftEvents/EventCreate'
import {EventEdit} from '../shiftEvents/EventEdit'

export function getItemId(sideCardMode: SideCardModes<ShiftEvent>): string | undefined {
  switch (sideCardMode.mode) {
    case 'detailsMode':
    case 'editMode':
      return sideCardMode.itemId
    default:
      return undefined
  }
}

type Props = SideCardModes<ShiftEvent> & {
  setMode: (next: SideCardModes<ShiftEvent>) => void
  doClose: (original?: ShiftEvent, next?: ShiftEvent) => void
  item?: ShiftEvent
  disableSap?: boolean
  isSeriesPreview?: boolean
  freeSearchText?: string
  eventTypeLabelPrefix?: string
  initialData?: Partial<ShiftEvent>
  onCreationSuccess?: (data: ShiftEvent) => void
  hideStoppageDateType?: boolean
}

// eslint-disable-next-line complexity
export const SideCard: React.FC<Props> = (props) => {
  const itemId = getItemId(props)
  const allowedToViewDocuments = usePermission('VIEW_DOCUMENTS')
  const [activeAttachmentId, setActiveAttachmentId] = useState<number | undefined>(undefined)
  const {t} = useTranslation()
  // FYI useEvent will first try to reuse the cash of the previous eventSearch before fetching the single item
  // as the image urls change on every call, we suppress it in edit mode to not end up with an invalid list of attachments
  const {
    data: item,
    error: errorOnUseEvent,
    isFetching,
    refetch
  } = useEvent(itemId, {
    refetchOnWindowFocus: props.mode === 'detailsMode',
    enabled: !props.item
  })
  const {plantId} = useConfig()
  const {pathname, search} = useLocation()
  const areChecklistsEnabled = useFeatureFlag('checklists')
  const canViewChecklists = usePermission('VIEW_CHECKLISTS')

  const showChecklists = areChecklistsEnabled && canViewChecklists

  const {data: taskChecklists = [], isInitialLoading: isLoadingChecklists} = useTaskChecklists(
    itemId,
    {
      enabled: !!itemId && !!item && isTask(item) && showChecklists
    }
  )

  const onSubmit = (next: ShiftEvent) => {
    // safety-net mostly for development
    // 'next' should always include a proper ShiftEvent
    // otherwise it will produce a non-speaking "can not read property 'toString' of undefined" higher in the tree
    if (!next) {
      throw new Error('onSubmit did not include an event object')
    } else if (Array.isArray(next)) {
      throw new Error('onSubmit received an array instead of an event object')
    } else if (!next.id) {
      throw new Error(`onSubmit was not able to extract an id from the given ${typeof next}`)
    }
    props.setMode({mode: 'detailsMode', itemId: next.id})
  }

  if (props.mode === 'createMode') {
    return (
      <EventCreate
        data-test-id="sideCard-create"
        doClose={props.doClose}
        key={'createMode'}
        onSubmit={onSubmit}
        preSetEventType={props.preSetEventType}
        initialData={props.initialData}
        onSuccess={props.onCreationSuccess}
        eventTypeLabelPrefix={props.eventTypeLabelPrefix}
        hideStoppageDateType={props.hideStoppageDateType}
        checklistsInputSettings={{
          showChecklists,
          disableSelect: false
        }}
      />
    )
  }

  if (errorOnUseEvent) {
    return <EventNotFound itemId={props.itemId} doClose={() => props.doClose()} />
  }

  const itemToUse = item || props.item
  const uniqueAttachments = getUniqueAttachments(itemToUse?.attachments, MAX_ALLOWED_ATTACHMENTS)
  if (itemToUse) {
    switch (props.mode) {
      case 'detailsMode':
        return (
          <EventDetails
            data-test-id="page-events-details"
            doClose={props.doClose}
            doEdit={(next: ShiftEvent) => {
              props.setMode({mode: 'editMode', itemId: next.id})
            }}
            doStoppageSplit={(initialStoppageData: Partial<Stoppage>) => {
              props.setMode({
                mode: 'createMode',
                initialData: initialStoppageData
              })
            }}
            item={itemToUse}
            onSapNotificationCreated={() => void refetch()}
            key={'detailsMode-' + props.itemId}
            disableSap={props.disableSap}
            isSeriesPreview={props.isSeriesPreview}
            freeSearchText={props.freeSearchText}
            hasChecklists={!!taskChecklists.length}
          >
            {isTask(itemToUse) && showChecklists && (
              <Box sx={{my: 1.5}}>
                <Tag labelKey="shiftEvent.label.safetyChecklists">
                  <EventTaskChecklists
                    checklists={taskChecklists}
                    isLoading={isLoadingChecklists}
                    translationPrefix="shiftEvent"
                    referer={{appName: 'Cockpit', pathname, search}}
                    plantId={plantId}
                  />
                </Tag>
              </Box>
            )}
            {allowedToViewDocuments && uniqueAttachments ? (
              <>
                <CardSectionTitle sx={headlineSx()}>
                  {t('shiftEvent.attachments')}{' '}
                  {`${uniqueAttachments.length}/${MAX_ALLOWED_ATTACHMENTS}`}
                </CardSectionTitle>
                <Divider sx={{borderColor: 'common.black'}} />
                <Box component={'span'} sx={imageBoxSx()}>
                  {!isFetching &&
                    uniqueAttachments.map((attachment, index) => (
                      <EventAttachment
                        key={`attachment-${attachment.id}`}
                        attachment={attachment}
                        setActiveAttachmentId={setActiveAttachmentId}
                        disableError={typeof activeAttachmentId === 'number' || !!index}
                      />
                    ))}
                </Box>
              </>
            ) : (
              <></>
            )}

            <Comments key={props.itemId} eventId={props.itemId} />
            {typeof activeAttachmentId === 'number' && (
              <EventAttachmentsDisplay
                attachments={uniqueAttachments}
                activeAttachmentId={activeAttachmentId}
                onClose={() => setActiveAttachmentId(undefined)}
                translationPrefix="shiftEvent"
              />
            )}
          </EventDetails>
        )

      case 'editMode':
        return (
          <EventEdit
            data-test-id="sideCard-edit"
            doClose={props.doClose}
            onSubmit={onSubmit}
            item={itemToUse}
            key={'editMode'}
            onlyEditSeries={props.isSeriesPreview}
            checklistsInputSettings={{
              showChecklists,
              disableSelect: true,
              initialValue: taskChecklists
            }}
          />
        )
    }
  }

  return (
    <ContentBox
      data-test-id="sideCard-spinner"
      isLoading
      minHeight={SIDE_CARD_MIN_HEIGHT_IN_PX}
      mode="max100PercentOfParentHeight"
    />
  )
}
