import {CommonDropDown, CommonProps} from '@hconnect/common/components'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useParameterChangeFunctions} from '../../hooks/api/ParameterChangeFunctions'

export const FunctionTitle: React.FC<{functionId?: string}> = ({functionId}) => {
  const {data: functions} = useParameterChangeFunctions()
  const {t} = useTranslation()

  const selectedFunction = functions?.find(({id}) => id === functionId)

  if (functionId && functions && selectedFunction) {
    return (
      <>
        {t(`shiftEvent.function.${selectedFunction.code}`, {defaultValue: selectedFunction.name})}
      </>
    )
  }

  return <>{functionId || ''}</>
}

export const ParameterChangeFunctionDropdown: React.FC<CommonProps<string>> = ({
  disabled,
  value,
  ...props
}) => {
  const {data: parameterChangeFunctions} = useParameterChangeFunctions()

  const option = useMemo(() => {
    if (!parameterChangeFunctions) {
      return value ? [value] : []
    }
    const functionIds = parameterChangeFunctions.map(({id}) => id)
    return value && !functionIds.includes(value) ? [value, ...functionIds] : functionIds
  }, [value, parameterChangeFunctions])

  return (
    <CommonDropDown
      {...props}
      value={value}
      disabled={disabled || option.length === 0}
      keyName="function"
      options={option}
      customTemplate={(key) => {
        return <FunctionTitle functionId={key} />
      }}
    />
  )
}
