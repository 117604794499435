import {
  EventDescriptionBox,
  CategoryDropDown,
  PriorityDropDown,
  ProcessStageDropDown,
  ProcessStageAutocomplete
} from '@hconnect/common/components/shiftEventFormFields'
import {EventHighlight} from '@hconnect/common/components/shiftEventFormFields/EventHighlight'
import {REGULAR_SIZE, INPUT_FORM_FIELD_SX} from '@hconnect/common/consts'
import {EquipmentIdType, EventType, Priority} from '@hconnect/common/types'
import {Grid, TextField, Box} from '@mui/material'
import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {isIdea, isInformation} from '../../common/utils/eventType'
import {useConfig} from '../../hooks/useConfig'
import {PossibleErrors, SharedEventStructure} from '../../types/shareEventForm.types'
import {BaseEvent, ShiftEvent} from '../../types/shiftHandover.types'
import {EquipmentSearchContainer} from '../eventProperties/EquipmentSearchContainer'
import {EventInfo} from '../eventProperties/EventInfo'
import {EventStatusDropDown} from '../eventProperties/EventStatus'
import {EventTypeDropdownBox} from '../eventProperties/EventTypeDropdownBox'
import {ScheduleSwitch} from '../eventProperties/ScheduleSwitch'

type Props = {
  item: Partial<Omit<SharedEventStructure, 'eventType'>> & {eventType: EventType}
  update: <K extends keyof SharedEventStructure>(
    key: K,
    value: SharedEventStructure[K] | EventType
  ) => void
  updateMultiPart: (delta) => void
  validationError: Map<PossibleErrors, string>
  children: React.ReactNode
  equipmentIdType?: EquipmentIdType
  autocompleteProcessStage?: boolean
}

export const eventDefaultObject = (
  eventType: SharedEventStructure['eventType'],
  initialData: Partial<ShiftEvent> = {}
): Partial<ShiftEvent> => {
  if (eventType === 'information' || eventType === 'idea') {
    return {
      description: '',
      eventType,
      status: 'none',
      title: '',
      ...omit(initialData, 'eventType')
    } as Partial<ShiftEvent>
  } // else
  return {
    description: '',
    eventType,
    status: 'pending',
    title: '',
    ...omit(initialData, 'eventType')
  } as Partial<ShiftEvent>
}

/**
 * if you consider using this component, check first src/container/EventFormContainer.tsx,
 * as it handles the validation and the stuff around this form,
 * it might already solve all your needs
 */
/* eslint-disable complexity */
export const SharedEventForm: React.FC<Props> = ({
  children,
  item,
  update,
  updateMultiPart,
  validationError,
  equipmentIdType,
  autocompleteProcessStage
}) => {
  const {t} = useTranslation()
  const {plantId} = useConfig()

  const getErrorText = (key: PossibleErrors) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  return (
    <>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          data-test-id="event-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t('shiftEvent.label.title')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            update('title', event.target.value)
          }
          required
          value={item.title}
          variant="filled"
          inputProps={{'data-test-id': 'event-form-title-input'}}
          InputProps={INPUT_FORM_FIELD_SX}
        />
        <EventHighlight
          title={t('eventsTable.action.highlight')}
          value={item.isHighlighted}
          onChange={(value) => update('isHighlighted', value)}
        />
      </Box>

      {'created' in item && <EventInfo item={item as Partial<SharedEventStructure>} />}

      <Box my={1}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item {...REGULAR_SIZE}>
            <EventTypeDropdownBox
              item={item as BaseEvent}
              value={item.eventType}
              updateMultiPart={updateMultiPart}
            />
          </Grid>

          <Grid item {...REGULAR_SIZE}>
            <EventStatusDropDown
              allow={
                isInformation(item as BaseEvent) || isIdea(item as BaseEvent) ? 'none' : undefined
              }
              data-test-id="event-form-status"
              errorText={getErrorText('status')}
              onChange={(value) => update('status', value)}
              required
              value={item.status}
            />
          </Grid>

          <Grid item xs={12}>
            <EquipmentSearchContainer
              data-test-id="event-form-equipmentNumber"
              errorText={getErrorText('equipmentNumber')}
              updateMultiPart={updateMultiPart}
              equipmentIdType={equipmentIdType}
              value={item as BaseEvent}
            />
          </Grid>

          {autocompleteProcessStage ? (
            <Grid item xs={12}>
              <ProcessStageAutocomplete
                plantId={plantId}
                data-test-id="event-form-processStage"
                errorText={getErrorText('processStage')}
                onChange={(value) => update('processStage', value)}
                value={item.processStage}
              />
            </Grid>
          ) : (
            <Grid item {...REGULAR_SIZE}>
              <ProcessStageDropDown
                plantId={plantId}
                data-test-id="event-form-processStage"
                errorText={getErrorText('processStage')}
                onChange={(value) => update('processStage', value)}
                value={item.processStage}
              />
            </Grid>
          )}
          <Grid item {...REGULAR_SIZE}>
            <CategoryDropDown
              data-test-id="event-form-category"
              errorText={getErrorText('category')}
              onChange={(value) => update('category', value)}
              required
              value={item.category}
            />
          </Grid>

          <Grid item {...REGULAR_SIZE}>
            <PriorityDropDown
              data-test-id="event-form-priority"
              errorText={getErrorText('priority')}
              onChange={(value) => update('priority', value)}
              value={item.priority as Priority}
            />
          </Grid>

          <ScheduleSwitch
            item={item}
            onChange={(value) => update('schedule', value)}
            errorText={getErrorText('schedule')}
          />

          <Grid item xs={12}>
            <EventDescriptionBox
              current={item.description || ''}
              errorCode={validationError.get('description')}
              onChange={(next) => update('description', next)}
              required
            />
          </Grid>
        </Grid>
      </Box>
      {children}
    </>
  )
}
