import {Env} from '@hconnect/common/hproduce/types'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {
  Notifications,
  TranslationKey,
  TranslationActionKey,
  Translations,
  NotificationsOptions,
  generateNotificationChannels
} from '@hconnect/common/notifications'
import {CommonHeader, useGuidedTours} from '@hconnect/uikit/src/lib2'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  matchPath,
  generatePath,
  useNavigate,
  useLocation,
  useSearchParams,
  SetURLSearchParams
} from 'react-router-dom'

import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {useGlobalContext} from '../../hooks/useGlobalContext'
import {useInstructionsPageLastVisited} from '../../hooks/useInstructionsPageLastVisited'
import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {links, links as linksList} from '../../routes'

export type Props = {
  onLogout?: () => void
}

const NOTIFICATIONS_TRANSLATION_KEYS: Translations = {
  label: {
    [TranslationKey.TODAY]: 'notifications.label.today',
    [TranslationKey.YESTERDAY]: 'notifications.label.yesterday',
    [TranslationKey.NOTIFICATIONS]: 'notifications.label.notifications',
    [TranslationKey.EMPTY]: 'notifications.label.empty',
    [TranslationKey.ERROR]: 'error.label.fetchingError',
    [TranslationKey.SHOULD_DELETE]: 'notifications.label.shouldDeleteNotification'
  },
  action: {
    [TranslationActionKey.DELETE]: 'notifications.action.delete',
    [TranslationActionKey.CONFIRM_DELETE]: 'notifications.action.confirmDelete',
    [TranslationActionKey.CANCEL]: 'action.cancel'
  }
}

export const HeaderContent: React.FC<Props> = (props) => {
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {user, config, whiteList} = useGlobalContext()
  const [queryParameters]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  const notificationsFeatureFlagEnabled = useFeatureFlag('notifications')
  const notificationsEnabled = notificationsFeatureFlagEnabled && props.onLogout && user

  const language = i18n.language
  const languagesRaw = i18n.options?.supportedLngs || [language]
  const languages = languagesRaw.filter((code) => code !== 'cimode')
  const selectedPlant = config.plantId

  const {hasUpdated: haveInstructionsUpdated} = useInstructionsPageLastVisited()

  const navItems = useMemo(() => {
    return linksList.map((link) => {
      const newURLSearchParams = new URLSearchParams()
      link.keepURLParams?.forEach((urlParam) => {
        const value = queryParameters.get(urlParam)
        if (value) {
          newURLSearchParams.append(urlParam, value)
        }
      })
      const params = newURLSearchParams.toString()
      const path = generatePath(link.to, {plantId: selectedPlant})

      return {
        label: t(link.translationKey),
        url: params ? `${path}?${params}` : path,
        showBadge: link.to.includes('documents') && haveInstructionsUpdated
      }
    })
  }, [selectedPlant, t, haveInstructionsUpdated, queryParameters])

  const options = useMemo<NotificationsOptions>(() => {
    const channels = generateNotificationChannels(whiteList)
    return {
      channels,
      countryCode: config.country
    }
  }, [whiteList, config.country])

  const {tours, tourStatuses} = useGuidedTours()

  const onNavigate = (url: string) => {
    const currentLocationKey = links.find((link) => matchPath(link.to, location.pathname))
      ?.translationKey
    const nextLocationKey = links.find((link) => matchPath(link.to, url))?.translationKey
    trackAnalyticsEvent('userMovesPage', {
      pageFrom: currentLocationKey && t(currentLocationKey, {lng: 'en'}),
      pageTo: nextLocationKey && t(nextLocationKey, {lng: 'en'})
    })
    navigate(url)
  }

  return (
    <CommonHeader
      additionalActions={
        notificationsEnabled
          ? [
              <Notifications
                key="notification-provider"
                options={options}
                translations={NOTIFICATIONS_TRANSLATION_KEYS}
                timezone={config.timezone}
              />
            ]
          : undefined
      }
      data-test-id="header-content"
      appName="Cockpit"
      plant={{plantName: getPlantName(selectedPlant), plantId: selectedPlant}}
      env={process.env.REACT_APP_STAGE as Env}
      whiteList={whiteList}
      navItems={navItems}
      supportedLanguages={languages}
      user={user}
      onLogout={props.onLogout}
      navigate={onNavigate}
      locationPath={location.pathname}
      enableMyTasks
      guidedTours={{tours, statuses: tourStatuses}}
      closeDropdownOnLanguageSwitch
    />
  )
}
