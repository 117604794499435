import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import React from 'react'

import {DesktopFilterOptionsKeys} from '../../common/utils/filterParameter'
import {useParameterChangeFunctions} from '../../hooks/api/ParameterChangeFunctions'
import {SelectAllButton} from '../common/SelectAllButton'
import {FunctionTitle} from '../eventProperties/ParameterChangeFunctionDropdown'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const FunctionsFilterDropDown: React.FC<CommonFilterDropDownProps<string>> = (props) => {
  const {data: functions = []} = useParameterChangeFunctions()
  const {selectAll} = props
  const options = functions.map(({id}) => id)
  const fieldKeyName: DesktopFilterOptionsKeys = 'parameterChangeFunctions'

  return (
    <FilterDropDown
      {...props}
      options={options}
      keyName="function"
      fullWidth={false}
      renderValue={(value: string[]) => {
        return value.map((val) => functions.find(({id}) => id === val)?.code || val || '').join(',')
      }}
      customTemplate={(key) => <FunctionTitle functionId={key} />}
      customDropDownFooter={() =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={fieldKeyName}
              selectAll={() => selectAll && selectAll(fieldKeyName, options)}
            />
            <RemoveFilterButton keyName={fieldKeyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
