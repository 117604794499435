import {DateRange} from '@hconnect/common/types'
import {QuickSelectSlots} from '@hconnect/common/utils'
import {isString} from 'lodash'
import {Moment} from 'moment-timezone'

export const formatDateTime = (date?: Moment, separator = '-'): string =>
  date ? date.format(`L ${separator} LT`) : '-'

export const plantTimezonedDateRangeValidator = (
  plantTimezone: string,
  dateRange?: DateRange | QuickSelectSlots
) => {
  if (
    dateRange &&
    !isString(dateRange) &&
    (dateRange.startDate.tz() !== plantTimezone || dateRange.endDate.tz() !== plantTimezone)
  ) {
    throw new Error(
      `Provided date range is not in the plant timezone. Expected: ${plantTimezone}, received: ${dateRange.startDate.tz()} - ${dateRange.endDate.tz()}`
    )
  }
}
