import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {ParameterChangeFunction} from '../../types/shiftHandover.types'

import {useApi} from './useApi'

const QueryKey = 'config-functions'

export const useParameterChangeFunctions = (
  options?: UseQueryOptions<ParameterChangeFunction[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {
    i18n: {language}
  } = useTranslation()
  const path = '/shifthandover/parameter-change-functions'

  return useQuery<ParameterChangeFunction[], AxiosError>(
    [QueryKey, language],
    async () => {
      const {data} = await axiosInstance.get<ParameterChangeFunction[]>(path)
      return data
    },
    options
  )
}
