import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {EventTypeLabel} from '@hconnect/common/components/shiftEventLabels'
import {filterSxStyles} from '@hconnect/common/styles'
import {EventType, StoppageKind} from '@hconnect/common/types'
import {TextField, MenuItem, Checkbox, ListItemText, Box} from '@mui/material'
import {isEmpty} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {isStoppageEventType} from '../../common/utils/eventType'
import {SelectAllButton} from '../common/SelectAllButton'

import {
  EventTypeFilterValue,
  everyStoppageCategoryChecked,
  preprocessValueList,
  stoppageCategoryChecked
} from './eventTypeFilterUtils'

interface EventTypeFilterDropDown {
  onChange: (newValue: EventType[] | undefined) => void
  value?: EventType[]
  removeFilter?: (filter: string) => void
  'data-test-id'?: string
  isCollapsible?: boolean
  selectAll: (options: EventType[]) => void
}

const eventAndStoppageCodeTypes: EventTypeFilterValue[] = [
  'stoppage',
  StoppageKind.Incident,
  StoppageKind.Circumstance,
  StoppageKind.PlannedStoppage,
  'malfunction',
  'healthAndSafety',
  'task',
  'information',
  'idea',
  'environment',
  'parameterChange',
  'quality'
]

export const EventTypeFilterDropDown: React.FC<EventTypeFilterDropDown> = ({
  value = [],
  removeFilter,
  onChange,
  isCollapsible = false,
  'data-test-id': dataTestId,
  selectAll
}) => {
  const {t} = useTranslation()
  const keyName = 'eventType'

  return (
    <Box display="flex">
      <TextField
        variant={'filled'}
        label={t('shiftEvent.label.eventType')}
        sx={filterSxStyles.textInputField}
        data-test-id={dataTestId}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const val: EventTypeFilterValue[] =
            (event.target.value as unknown as EventTypeFilterValue[] | undefined) ?? []
          const eventTypeList = preprocessValueList(val)
          onChange(isEmpty(eventTypeList) ? undefined : eventTypeList)
        }}
        SelectProps={{
          multiple: true,
          renderValue: (args: unknown) =>
            (args as EventTypeFilterValue[])
              .map((arg: EventTypeFilterValue) => t(`shiftEvent.eventType.${arg}`))
              .join(', ')
        }}
        select
      >
        {eventAndStoppageCodeTypes.map((option) => (
          <MenuItem key={option} value={option} data-test-id={`${dataTestId}-${option}`}>
            <Checkbox
              sx={[
                {
                  pl: 0,
                  py: 0,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                },
                isStoppageEventType(option) && {ml: 4}
              ]}
              checked={
                option === 'stoppage' ? everyStoppageCategoryChecked(value) : value.includes(option)
              }
              indeterminate={
                option === 'stoppage' &&
                stoppageCategoryChecked(value) &&
                !everyStoppageCategoryChecked(value)
              }
            />
            {isStoppageEventType(option) ? (
              <ListItemText>{t(`shiftEvent.eventType.${option}`)}</ListItemText>
            ) : (
              <EventTypeLabel eventType={option} />
            )}
          </MenuItem>
        ))}
        {isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() => {
                const allValues = eventAndStoppageCodeTypes.filter(
                  (v) => v !== 'stoppage'
                ) as unknown as EventTypeFilterValue[]
                const processed = preprocessValueList(allValues)
                selectAll(processed)
              }}
            />
            <RemoveFilterButton keyName={keyName} removeFilter={removeFilter} />
          </>
        )}
      </TextField>
    </Box>
  )
}
