import {ConfigContext as BaseConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {DateRange, ParticularShift} from '@hconnect/common/types'
import {getShift} from '@hconnect/common/utils'

type ShiftCache = {
  expiresAfter: number // utc miliseconds
  particularShift: ParticularShift
  dateRange: DateRange
}

export class ConfigContext<T extends string> extends BaseConfigContext<T> {
  private currentShiftCache: ShiftCache | undefined

  public getCurrentShift = (): ParticularShift => {
    const nowMoment = this.plantNow()
    const nowUnixTimeStamp = nowMoment.valueOf()

    if (
      !this.currentShiftCache?.particularShift ||
      this.currentShiftCache.expiresAfter < nowUnixTimeStamp
    ) {
      const particularShift = getShift(nowMoment, this.shifts)

      const endDate = particularShift.endDate
      const startDate = particularShift.startDate

      this.currentShiftCache = {
        particularShift,
        expiresAfter: endDate.valueOf(),
        dateRange: {
          startDate,
          endDate
        }
      }
    }

    return this.currentShiftCache.particularShift
  }
}
