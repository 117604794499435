import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useIsMobile} from '@hconnect/uikit/src/lib2'
import {Grid} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  defaultDesktop,
  DesktopShiftSummaryFilterOptionsKeys,
  Page
} from '../common/utils/filterParameter'
import {PerformanceDataView} from '../containers/performanceData'
import {useCancelInactiveQueries} from '../hooks/useCancelInactiveQueries'
import {usePageFilters} from '../hooks/useEventsFilter'
import {useTrackAnalyticsEvent} from '../hooks/useTrackAnalyticsEvents'
import {EventsFilterBar} from '../layouts/FilterBar'
import {getFilterDifference} from '../utils/filter.utils'

export const PerformancePage = () => {
  const {t} = useTranslation()
  const [isMobileFilterOpen, openMobileFilter] = useState(false)
  const isMobile = useIsMobile()
  useCancelInactiveQueries()
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  const [filterOptions, setFilters] = usePageFilters(Page.Performance)

  return (
    <FlexPage
      appName="Cockpit"
      title={isMobileFilterOpen ? t('shiftEvent.action.filter') : t('performanceData.pageName')}
      sxActionContent={isMobileFilterOpen ? {width: '100%'} : undefined}
      headerActionContent={
        <Grid container alignItems="center">
          <Grid item xs={isMobileFilterOpen ? 12 : undefined}>
            <EventsFilterBar<DesktopShiftSummaryFilterOptionsKeys>
              activeSettings={filterOptions}
              isCollapsible={isMobile}
              options={[]}
              onChange={(nextFilterOptions) => {
                setFilters(nextFilterOptions)
                trackAnalyticsEvent('userAdjustsFilter', {
                  filterPage: Page.Performance,
                  filterName: getFilterDifference(nextFilterOptions, filterOptions)
                })
              }}
              openMobileFilter={openMobileFilter}
              isMobileFilterOpen={isMobileFilterOpen}
              defaultFilters={defaultDesktop}
            />
          </Grid>
        </Grid>
      }
    >
      {!isMobileFilterOpen && <PerformanceDataView />}
    </FlexPage>
  )
}
