import React, {FC} from 'react'

import {AllFilterOptions} from '../../common/utils/filterParameter'
import {useConfig} from '../../hooks/useConfig'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'

import {ProcessStageFilterAutocomplete} from './ProcessStageFilterAutocomplete'
import {ProcessStageFilterDropDown} from './ProcessStageFilterDropDown'

type ProcessStageFilterProps = {
  visibleFilters: string[]
  onChange: (filterOptions: AllFilterOptions) => void
  settings: AllFilterOptions
  removeFilter: (filterName: string) => void
  isCollapsible: boolean
  selectAll?: (filter: string, options: string[]) => void
}

export const ProcessStageFilter: FC<ProcessStageFilterProps> = ({
  visibleFilters,
  onChange,
  settings,
  removeFilter,
  isCollapsible,
  selectAll
}) => {
  const autocompleteProcessStage = useFeatureFlag('autocompleteProcessStage')
  const {plantId} = useConfig()

  return (
    <>
      {visibleFilters.includes('processStage') &&
        (autocompleteProcessStage ? (
          <ProcessStageFilterAutocomplete
            plantId={plantId}
            data-test-id="events-filter-bar-processStage"
            onChange={(next?: string[]) => {
              onChange({...settings, processStage: next})
            }}
            value={settings.processStage}
          />
        ) : (
          <ProcessStageFilterDropDown
            data-test-id={'events-filter-bar-processStage'}
            onChange={(value) => onChange({...settings, processStage: value})}
            value={settings.processStage}
            removeFilter={removeFilter}
            isCollapsible={isCollapsible}
            selectAll={selectAll}
          />
        ))}
    </>
  )
}
