import {
  shiftPickLabel,
  handleDateChange,
  ShiftDropdown,
  getShiftRange
} from '@hconnect/common/components/dateTimeAndShift/ShiftDropdown'
import {getResponsivePopoverStyle} from '@hconnect/common/styles'
import {ParticularShift} from '@hconnect/common/types'
import {Dates, formatDateLocale} from '@hconnect/uikit'
import {Calendar, DatePickerTextField} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {Popover, Box, Checkbox, FormControlLabel} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'

interface Props {
  error?: boolean
  enableSelectRange?: boolean
  date?: Moment | null
  dateRange?: [Moment | null, Moment | null]
  noAutoClose?: boolean
  label: React.ReactNode
  onChange: (value: ParticularShift | [ParticularShift, ParticularShift]) => void
}

const SimpleShiftPicker: React.FC<Props> = (props) => {
  const config = useConfig()

  const {shifts, timezone} = config

  const {date, dateRange = [], noAutoClose, onChange, label, enableSelectRange} = props
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const [startDate, endDate] = dateRange

  const [isRangeSelect, setShowRangeSelect] = useState(!!((date || startDate) && endDate))
  const [selectedStartDate, setSelectedStartDate] = useState(date || startDate || null)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate || null)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const noDateText = t('datePicker.chooseDate')

  const close = useCallback(() => setOpen(false), [setOpen])
  const selectionText = useMemo(() => {
    if (selectedEndDate && selectedStartDate) {
      return `${formatDateLocale(selectedStartDate, language)} - ${formatDateLocale(
        selectedEndDate,
        language
      )}`
    }
    if (selectedStartDate) {
      return shiftPickLabel(selectedStartDate, {shifts})
    }

    return noDateText
  }, [selectedEndDate, selectedStartDate, noDateText, language, shifts])

  const handleSingleDateChange = (date: Moment | null) => {
    if (!date) return
    const selectedShift = handleDateChange(date, {shifts})
    const shiftRange = getShiftRange(selectedShift)
    setSelectedStartDate(shiftRange.startDate)

    onChange(selectedShift)
    !noAutoClose && close()
  }

  const handleDateRangeChange = useCallback(
    (dates: Dates) => {
      setSelectedStartDate(dates.startDate || null)
      setSelectedEndDate(dates.endDate || null)

      if (dates.endDate && dates.startDate) {
        const selectedStart = handleDateChange(dates.startDate, {shifts})
        const selectedEnd = handleDateChange(dates.endDate, {shifts})
        const shiftRange = getShiftRange(selectedStart)
        setSelectedStartDate(shiftRange.startDate)

        onChange([selectedStart, selectedEnd])
      }
    },
    [onChange, shifts]
  )

  const calendarProps = {
    timezone,
    startDate: selectedStartDate,
    ...(isRangeSelect
      ? {endDate: selectedEndDate, onRangeChange: handleDateRangeChange}
      : {onDateChange: handleSingleDateChange})
  }

  return (
    <Box
      sx={{
        padding: 0,
        position: 'relative',
        zIndex: 999,
        width: '100%',
        height: 'inherit'
      }}
    >
      <DatePickerTextField
        error={props.error ? t('datePicker.requiredFieldErr') : undefined}
        buttonRef={anchorRef}
        focus={open}
        setFocus={setOpen}
        text={selectionText}
        label={label || t('datePicker.label')}
      />
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          id: 'simple-date-picker-popover-paper',
          sx: getResponsivePopoverStyle
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356
          }}
        >
          <Calendar {...calendarProps} />
          {enableSelectRange && (
            <Box
              sx={(theme) => ({
                color: theme.palette.text.primary,
                width: '100%'
              })}
            >
              <FormControlLabel
                sx={{mb: 1}}
                control={
                  <Checkbox
                    data-test-id="range-select"
                    checked={isRangeSelect}
                    onChange={() => {
                      setShowRangeSelect((prev) => {
                        if (prev) {
                          handleSingleDateChange(selectedStartDate)
                          setSelectedEndDate(null)
                        }
                        return !prev
                      })
                    }}
                  />
                }
                label={t('datePicker.selectRange')}
              />
            </Box>
          )}
          <Box width={'100%'}>
            <ShiftDropdown
              date={selectedStartDate}
              shifts={shifts}
              onChange={(selectedShift: ParticularShift) => {
                const shiftRange = getShiftRange(selectedShift)
                setSelectedStartDate(shiftRange.startDate)
                if (selectedEndDate) {
                  const shiftRange = getShiftRange(selectedShift)
                  handleDateRangeChange({
                    startDate: shiftRange.endDate,
                    endDate: selectedEndDate
                  })
                } else {
                  onChange(selectedShift)
                }
              }}
              label={t('datePicker.label')}
              keyName={selectedEndDate ? 'startDateShift' : 'shift'}
            />
          </Box>
          {isRangeSelect && selectedEndDate && (
            <Box width="100%" mt={1}>
              <ShiftDropdown
                date={selectedEndDate}
                shifts={shifts}
                onChange={(selectedShift: ParticularShift) => {
                  const shiftRange = getShiftRange(selectedShift)
                  setSelectedEndDate(shiftRange.endDate)
                  handleDateRangeChange({
                    startDate: selectedStartDate,
                    endDate: shiftRange.endDate
                  })
                }}
                label={t('datePicker.label')}
                keyName="endDateShift"
              />
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  )
}

export {SimpleShiftPicker}
