import {LoginFlow, User} from '@hconnect/apiclient'
import {PlantSelect as PlantSelectComponents} from '@hconnect/common/components'
import plants from '@hconnect/common/hproduce/plants.json'
import {Env, PlantSelectOption, WhiteList} from '@hconnect/common/hproduce/types'
import {Shell, ShellFooter, PageContainer, CommonHeader} from '@hconnect/uikit/src/lib2'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'

import {routeToEvents} from '../routes'

type PlantSelectProps = {
  whiteList: WhiteList
  loginFlow: LoginFlow
  user: User
}

export const PlantSelect: FC<PlantSelectProps> = ({whiteList, loginFlow, user}) => {
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const allowedPlants = whiteList.optionsPerApp.Cockpit || []

  const plantsList: PlantSelectOption[] = allowedPlants.map(({plantId}) => {
    return {
      plantId,
      plantName: plants[plantId]?.name || plantId,
      url: generatePath(routeToEvents, {plantId})
    }
  })

  if (plantsList.length === 1) {
    return <Navigate to={plantsList[0].url} />
  }

  const language = i18n.language
  const languagesRaw = i18n.options?.supportedLngs || [language]
  const languages = languagesRaw.filter((code) => code !== 'cimode')

  return (
    <Shell
      isResponsive
      header={
        <CommonHeader
          appName="Cockpit"
          onLogout={() => void loginFlow.startLogoutProcess()}
          supportedLanguages={languages}
          user={{email: user.eMail || undefined, name: user.name || undefined}}
          env={process.env.REACT_APP_STAGE as Env}
          whiteList={whiteList}
          navigate={(url) => navigate(url)}
          locationPath={location.pathname}
          enableMyTasks
          closeDropdownOnLanguageSwitch
        />
      }
      footer={<ShellFooter label={t('footer.poweredBy')} />}
    >
      <PageContainer>
        <PlantSelectComponents plants={plantsList} />
      </PageContainer>
    </Shell>
  )
}
