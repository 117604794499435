import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {useEndOfPageScrollDebounce} from '@hconnect/common/hooks/useListScrollDebounce'
import {EventsTab} from '@hconnect/common/types'
import {getQuickSelectFilter} from '@hconnect/common/utils'
import type {PaginationOptions} from '@hconnect/uikit/src/lib2'
import {Theme, useMediaQuery} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {generatePath, useNavigate} from 'react-router-dom'

import {downloadBlob} from '../../common/utils/downloadHelper'
import {FilterOptions, Page} from '../../common/utils/filterParameter'
import {useEventsSearch} from '../../hooks/api/useEventsSearch'
import {useEventsSearchDownload} from '../../hooks/api/useEventsSearchDownload'
import {useConfig} from '../../hooks/useConfig'
import {usePageFilters} from '../../hooks/useEventsFilter'
import {useOnPageWithoutUnmountFocus} from '../../hooks/useOnPageWithoutUnmountFocus'
import {useQueryParameterForViewMode, viewModeToUrlParams} from '../../hooks/useQueryParameter'
import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {routeToEvents} from '../../routes'
import {ShiftEvent, SortBy, SortOrder} from '../../types/shiftHandover.types'
import {getFilterDifference} from '../../utils/filter.utils'

import {EventsView} from './EventsView'

const createUrl = (nextViewMode: ViewMode<ShiftEvent>, plantId: string): string => {
  const viewModeParams = viewModeToUrlParams(nextViewMode)
  return `${generatePath(routeToEvents, {plantId})}?${viewModeParams}`
}

const MOBILE_ELEMENTS_PER_PAGE = 50

type EventsContainerProps = {
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
}

export const EventsContainer: React.FC<EventsContainerProps> = ({activeTab, setActiveTab}) => {
  const navigate = useNavigate()
  const config = useConfig()
  const viewMode = useQueryParameterForViewMode()
  const trackAnalyticsEvent = useTrackAnalyticsEvent()
  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(50)
  const [filterOptions, setFilters] = usePageFilters(Page.Events, {
    sortBy: SortBy.Created,
    sortOrder: SortOrder.Descending
  })
  // on the Events page there is a default time range that:
  // - does not apply for all users of useEventsSearch and
  // - should not end up as part of the url
  // there for a special filter option setting just for useEventsSearch is needed
  const extendedFilterOptions: FilterOptions = {
    ...filterOptions
  }
  if (!extendedFilterOptions.timeRange) {
    const [startDate, endDate] = getQuickSelectFilter(
      config.defaultTimeRange,
      config.shifts,
      config.plantNow()
    )

    extendedFilterOptions.timeRange = {
      startDate: startDate,
      endDate: endDate
    }
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const {data, isInitialLoading, isFetching, refetch} = useEventsSearch(
    extendedFilterOptions,
    extendedFilterOptions.sortBy || SortBy.Created,
    extendedFilterOptions.sortOrder,
    pageNumber,
    itemsPerPage,
    {
      keepPreviousData: isMobile,
      refetchOnWindowFocus: false
    }
  )
  useOnPageWithoutUnmountFocus(refetch)

  const eventDownloadQuery = useEventsSearchDownload(
    extendedFilterOptions,
    extendedFilterOptions.sortBy || SortBy.Created,
    extendedFilterOptions.sortOrder,
    pageNumber,
    itemsPerPage
  )

  const shouldLoadNextPage = itemsPerPage < (data?.totalItemCount || 0)

  const {trackScrolling} = useEndOfPageScrollDebounce(() => {
    setItemsPerPage((items) => items + MOBILE_ELEMENTS_PER_PAGE)
  }, shouldLoadNextPage)

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', trackScrolling)
    }

    return () => document.removeEventListener('scroll', trackScrolling)
  }, [isMobile, trackScrolling])

  useEffect(() => {
    if (isMobile) {
      setItemsPerPage(MOBILE_ELEMENTS_PER_PAGE)
      changePage(0)
    }
  }, [isMobile])

  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }

  const paginationOptions: PaginationOptions = {
    page: pageNumber,
    rowsPerPage: itemsPerPage,
    onPaginationChange: onPaginationChange,
    totalDataLength: data?.totalItemCount ?? 0
  }

  return (
    <EventsView
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      activeFilter={filterOptions}
      events={data?.events}
      isLoading={isInitialLoading || isFetching}
      viewMode={viewMode}
      initialCreateData={viewMode.mode === 'createMode' ? viewMode.initialData : undefined}
      setViewMode={(nextViewMode) => {
        navigate(createUrl(nextViewMode, config.plantId))
      }}
      onFilterChange={(nextFilterOptions) => {
        changePage(0)
        if (isMobile) {
          setItemsPerPage(MOBILE_ELEMENTS_PER_PAGE)
        }
        setFilters(nextFilterOptions)
        trackAnalyticsEvent('userAdjustsFilter', {
          filterPage: Page.Events,
          filterName: getFilterDifference(nextFilterOptions, filterOptions)
        })
      }}
      isEventDownloadLoading={eventDownloadQuery.isRefetching}
      onEventsDownload={async () => {
        const response = await eventDownloadQuery.refetch()
        if (response.data) {
          downloadBlob(response.data, `events_${config.plantId}.xlsx`)
          trackAnalyticsEvent('userDownloadsEventsList', {})
        }
      }}
      paginationOptions={isMobile ? undefined : paginationOptions}
      showBottomProgress={isMobile && shouldLoadNextPage}
    />
  )
}
