import {EventAttachmentUpload} from '@hconnect/common/components/attachments'
import {EventType, AttachmentFile, ParticularShift, StoppageKind} from '@hconnect/common/types'
import {stoppageDefaultObject} from '@hconnect/common/utils'
import {LoadingSwitcher} from '@hconnect/uikit/src/lib2'
import React, {useCallback, useEffect, useState} from 'react'
import {v4 as uuid} from 'uuid'

import {isTask} from '../../common/utils/eventType'
import {parameterChangeDefaultObject} from '../../components/eventForm/ParameterChangeForm'
import {eventDefaultObject} from '../../components/eventForm/SharedEventForm'
import {taskDefaultObject} from '../../components/eventForm/TaskForm'
import {EventTypeSelectBox} from '../../components/eventProperties/EventTypeSelectBox'
import {useEventCreate} from '../../hooks/api/useEventCreate'
import {useConfig} from '../../hooks/useConfig'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {useHasPermission, usePermission} from '../../hooks/usePermission'
import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {ChecklistsInputSettings} from '../../types/shareEventForm.types'
import {
  EventCreate as EventCreateDto,
  ShiftEvent,
  BaseEvent,
  Task
} from '../../types/shiftHandover.types'

import {EventFormContainer} from './EventFormContainer'

const createNew = (
  eventType: EventType,
  currentShift: ParticularShift,
  initialData: Partial<ShiftEvent> = {}
): Partial<ShiftEvent> => {
  switch (eventType) {
    case StoppageKind.Incident:
    case StoppageKind.Circumstance:
    case StoppageKind.PlannedStoppage:
    case StoppageKind.Other:
      return {
        ...stoppageDefaultObject<Partial<ShiftEvent>>(initialData),
        eventType
      } as Partial<ShiftEvent>

    case 'task': {
      const temp = taskDefaultObject()
      if (isTask(temp as BaseEvent)) {
        ;(temp as Task).dueDate = currentShift.endDate
      }
      return temp
    }

    case 'parameterChange': {
      return parameterChangeDefaultObject()
    }

    default:
      return eventDefaultObject(eventType, initialData)
  }
}

type Props = {
  'data-test-id'?: string
  eventTypeLabelPrefix?: string
  initialData?: Partial<ShiftEvent>
  preSetEventType?: EventType
  hideStoppageDateType?: boolean
  checklistsInputSettings?: ChecklistsInputSettings
  doClose(original?: ShiftEvent, newVersion?: ShiftEvent): void
  onSubmit?(item: ShiftEvent): void
  onSuccess?(data: ShiftEvent): void
}

export const EventCreate: React.FC<Props> = ({
  doClose,
  onSubmit,
  onSuccess,
  preSetEventType,
  initialData,
  eventTypeLabelPrefix,
  hideStoppageDateType,
  checklistsInputSettings,
  ...props
}) => {
  const config = useConfig()
  const hasPermission = useHasPermission()
  const isMovieAttachmentEnabled = useFeatureFlag('movieAttachment')
  const [files, setFiles] = useState<Array<AttachmentFile>>([])
  const [eventCreationUuid, setEventCreationUuid] = useState<string | null>(null)
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  const createAction = useEventCreate({
    onSuccess
  })
  const allowedToAddDocuments = usePermission('ADD_DOCUMENTS')
  const {data} = createAction

  useEffect(() => {
    if (data) {
      onSubmit?.(data)
    }
  }, [onSubmit, data])
  const preSelectedEventType = preSetEventType || initialData?.eventType
  const [item, setItem] = useState<Partial<ShiftEvent> | undefined>(
    (preSelectedEventType &&
      createNew(preSelectedEventType, config.getCurrentShift(), initialData)) ||
      undefined
  )

  const onEventTypeSelect = useCallback(
    (eventType: EventType) => {
      const processUuid = uuid()
      trackAnalyticsEvent('onCreationStartEvent', {
        uuid: processUuid
      })
      setEventCreationUuid(processUuid)
      setItem(createNew(eventType, config.getCurrentShift()))
    },
    [config, trackAnalyticsEvent]
  )

  const doSubmit = React.useCallback(
    (item: EventCreateDto) => {
      createAction.mutate(
        {
          ...item,
          attachments: files
        },
        {
          onSuccess: () => {
            eventCreationUuid &&
              trackAnalyticsEvent('onCreationFinishEvent', {
                uuid: eventCreationUuid
              })
          }
        }
      )
    },
    [createAction, files, eventCreationUuid, trackAnalyticsEvent]
  )

  return (
    <>
      <LoadingSwitcher isLoading={createAction.isLoading}>
        {item ? (
          <EventFormContainer
            data-test-id={props['data-test-id']}
            isCreateMode={true}
            item={item}
            doClose={() => doClose()}
            doSubmit={doSubmit}
            eventTypeLabelPrefix={eventTypeLabelPrefix}
            hideStoppageDateType={hideStoppageDateType}
            checklistsInputSettings={checklistsInputSettings}
          >
            {allowedToAddDocuments && (
              <EventAttachmentUpload
                isMovieAttachmentEnabled={isMovieAttachmentEnabled}
                setAttachments={setFiles}
                attachments={files}
                hasPermission={hasPermission}
              />
            )}
          </EventFormContainer>
        ) : (
          <EventTypeSelectBox onSelect={onEventTypeSelect} doClose={doClose} />
        )}
      </LoadingSwitcher>
    </>
  )
}
