import {ManualKpiPreview} from '@hconnect/common/components/kpiPerformance/ManualKpiPreview'
import {ExpandedGroupIds} from '@hconnect/common/hooks/useExpandGroups'
import {ManualKpiResolution, ManualKpiToUpdate, ProcessStage} from '@hconnect/common/types'
import {generateKpi, generateKpisToUpdate} from '@hconnect/common/utils'
import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'
import React, {FC} from 'react'
import {Control} from 'react-hook-form'

import {ManualKpiEdit} from '../../components/kpis'
import {useManualKpis, useKpiSuggestions} from '../../hooks/api/kpis'
import {useFilterDatePicker} from '../../hooks/useDatePicker'
import {KpiFormData} from '../../types/kpi.types'

type PerformanceManualKpiContainerProps = {
  isEditMode: boolean
  isEnabled: boolean
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  processStages: ProcessStage[]
  isProcessStagesLoading: boolean
  manualKpiList: ManualKpiToUpdate[]
  setManualKpiList: (manualKpiList: ManualKpiToUpdate[]) => void
  control: Control<KpiFormData>
  updateError: AxiosError | null
  updateReset: () => void
}

export const PerformanceManualKpiContainer: FC<PerformanceManualKpiContainerProps> = ({
  isEditMode,
  isEnabled,
  openProcessStages,
  setOpenProcessStages,
  processStages,
  isProcessStagesLoading,
  manualKpiList,
  setManualKpiList,
  control,
  updateError,
  updateReset
}) => {
  const {timeRange, isSingleShift} = useFilterDatePicker()

  const {data: kpis, isInitialLoading: isKpisLoading} = useManualKpis(
    isSingleShift ? ManualKpiResolution.PerShift : ManualKpiResolution.Daily,
    timeRange.endDate,
    {
      enabled: isEnabled,
      onSuccess: (data) => {
        if (!isEmpty(data)) {
          setManualKpiList(generateKpisToUpdate(data))
        }
      }
    }
  )
  useKpiSuggestions({
    enabled: !isKpisLoading && isEmpty(kpis) && isEnabled,
    onSuccess: (data) => {
      if (!isKpisLoading && isEmpty(kpis)) {
        const kpiSuggestions = data.map((kpi) => generateKpi(kpi))
        setManualKpiList(kpiSuggestions)
      }
    }
  })

  return isEditMode ? (
    <ManualKpiEdit
      setManualKpiList={setManualKpiList}
      processStages={processStages}
      manualKpiList={manualKpiList}
      isProcessStagesLoading={isProcessStagesLoading}
      openProcessStages={openProcessStages}
      setOpenProcessStages={setOpenProcessStages}
      control={control}
      updateError={updateError}
      updateReset={updateReset}
    />
  ) : (
    <ManualKpiPreview
      manualKpiList={manualKpiList}
      isProcessStagesLoading={isProcessStagesLoading}
      openProcessStages={openProcessStages}
      setOpenProcessStages={setOpenProcessStages}
      processStages={processStages}
    />
  )
}
