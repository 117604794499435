import {User} from '@hconnect/common/types'
import {Moment} from 'moment'
import {FC} from 'react'

import {Iso8601} from './atomic.types'
import {WorkOrderStatus, PmNotificationStatus} from './shiftHandover.types'

export type Equipment = {
  id: string
  text: string
}

export type NotificationDto = {
  id: string
  createdAt: Iso8601
  status: PmNotificationStatus
  objectNumber?: string
  docNumber?: string
  title?: string
  equipment?: Equipment
}

export type Notification = {
  id: string
  createdAt: Moment
  status: PmNotificationStatus
  objectNumber?: string
  docNumber?: string
  title?: string
  equipment?: Equipment
}

export type WorkOrderDto = {
  id: string
  pmNotificationId?: string
  title?: string
  status: WorkOrderStatus
  priority?: number
  createdBy: User
  updatedBy?: User
  equipment: Equipment
  functionalLocation?: string
  createdAt?: Iso8601
  startDate?: Iso8601
  endDate?: Iso8601
  orderType?: string
  workCenter?: string
  costCenter?: string
  profitCenter?: string
  activityType?: string
  duration?: string
  responsible?: User
}
export type WorkOrder = {
  id: string
  pmNotificationId?: string
  title?: string
  status: WorkOrderStatus
  priority?: number
  createdBy: User
  updatedBy?: User
  equipment: Equipment
  functionalLocation?: string
  createdAt?: Moment
  startDate?: Moment
  endDate?: Moment
  orderType?: string
  workCenter?: string
  costCenter?: string
  profitCenter?: string
  activityType?: string
  duration?: string
  responsible?: User
}

export type PaginationParams<T> = {
  items: T[]
  total: number
}
export type NotificationPagedContainerDto = PaginationParams<NotificationDto>
export type NotificationPagedContainer = PaginationParams<Notification>
export type WorkOrderPagedContainer = PaginationParams<WorkOrder>

export type WorkOrdersPayload<T> = {
  input: T
  offset: number
  limit: number
}

export type SideCardComponentProps<T> = FC<{
  item: T | undefined
  itemId: string
  doClose: () => void
}>

export type OrderType = {
  code: string
  name: string
}
