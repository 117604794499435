import {useEquipment} from '@hconnect/common/hooks/useEquipment'
import {EquipmentIdType, WorkCenter} from '@hconnect/common/types'
import {validateAll, ValidatorConfig} from '@hconnect/common/validators'
import {Close, FileUpload} from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Box
} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentSearchContainer} from '../../components/eventProperties/EquipmentSearchContainer'
import {useEventUpdate} from '../../hooks/api/useEventUpdate'
import {usePlannerGroups} from '../../hooks/api/usePlannerGroups'
import {useConfig} from '../../hooks/useConfig'
import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {sapPm} from '../../translations'
import {CreateMaintenanceNotification} from '../../types/maintenanceNotification.types'
import {
  BaseEvent,
  EventUpdate,
  ShiftEvent,
  ShiftEventAndUpdateEvent
} from '../../types/shiftHandover.types'
import {getFormPrimaryButtonSx} from '../../utils'

import {getTypeSpecificValidatorConfig} from './EventFormContainer'
import {WorkCenterSearchContainer} from './WorkCenterSearchContainer'

type ErrorCase = 'conflictError' | 'validationError' | 'unknownError'

type DialogProps = {
  open: boolean
  event: ShiftEvent
  onClose: () => void
  onConfirm: (notification: CreateMaintenanceNotification) => void
}

const translate = sapPm.dialog.equipmentUpdate

export const EquipmentUpdateDialog: React.FC<DialogProps> = (props) => {
  const {i18n, t} = useTranslation()
  const [settings, setSettings] = useState<Partial<BaseEvent> | null>(null)
  const [error, setError] = useState<ErrorCase | null>(null)
  const [plannerGroup, setPlannerGroup] = useState<string>('')
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  // try to determine default work center
  const mergedEvent = {...props.event, ...settings}
  const {equipment, id: eventId} = mergedEvent
  const equipmentId = equipment?.id
  const equipmentIdType = equipment?.idType
  const {workCenters, plantId, timezone} = useConfig()
  const {data: plannerGroups} = usePlannerGroups()
  const equipmentPlain = useEquipment(
    plantId,
    equipmentId as string,
    equipmentIdType as EquipmentIdType,
    i18n.language,
    {
      enabled: Boolean(equipmentId && equipmentIdType)
    }
  )

  const [selectedWorkCenter, setSelectedWorkCenter] = useState<WorkCenter | undefined>()

  const updateAction = useEventUpdate({
    onError: (updateError) => {
      if (updateError.response?.status === 409) {
        setError('conflictError')
      } else {
        setError('unknownError')
      }
    },
    onSuccess: () => {
      props.onConfirm({
        sourceEventId: eventId,
        workCenterId: selectedWorkCenter?.id,
        plannerGroupId: plannerGroup
      })
    }
  })

  useEffect(() => {
    const defaultWorkCenter = workCenters.find(
      (wc) => wc.id === equipmentPlain.data?.defaultWorkCenterId
    )
    setSelectedWorkCenter(defaultWorkCenter)
  }, [equipmentPlain.data?.defaultWorkCenterId, workCenters])

  useEffect(() => {
    const plannerGroupId = equipmentPlain.data?.defaultPlannerGroup
    setPlannerGroup(plannerGroupId ?? '')
  }, [equipmentPlain.data?.defaultPlannerGroup])

  const onExit = () => {
    setSettings(null)
    setError(null)
  }

  const onSend = () => {
    if (!settings) {
      props.onConfirm({
        sourceEventId: eventId,
        workCenterId: selectedWorkCenter?.id,
        plannerGroupId: plannerGroup
      })
      return
    }

    const validatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent> =
      getTypeSpecificValidatorConfig(mergedEvent.eventType)
    const validationError = validateAll<ShiftEventAndUpdateEvent>(
      validatorConfig,
      mergedEvent as BaseEvent
    )

    if (validationError.size !== 0) {
      setError('validationError')
    }

    trackAnalyticsEvent('onSapPmNotificationFeature', {
      timezone
    })

    updateAction.mutate({
      event: mergedEvent as unknown as EventUpdate
    })
  }

  const onEquipmentChange = (next: Partial<BaseEvent>) => {
    setSettings(!next || !next.equipment ? null : next)
  }

  return (
    <Dialog
      TransitionProps={{
        onExit
      }}
      open={props.open}
      maxWidth="sm"
      data-test-id="equipment-update-dialog"
      onClose={props.onClose}
    >
      <DialogTitle>
        {t(props.event.equipment ? t('sapPm.label.workCenterQuestion') : translate.header)}
      </DialogTitle>
      <DialogContent>
        {error ? (
          t(translate[error])
        ) : (
          <>
            {!props.event.equipment && (
              <>
                {t(translate.body)}
                <EquipmentSearchContainer
                  value={props.event}
                  updateMultiPart={onEquipmentChange}
                  hideMainEquipmentField
                  required
                />
                {t('sapPm.label.workCenterQuestion')}
              </>
            )}
            <WorkCenterSearchContainer
              required={false}
              workCenter={selectedWorkCenter}
              handleWorkCenterSelection={(workCenter) => setSelectedWorkCenter(workCenter)}
            />
            <Box pt={2}>
              <TextField
                data-test-id={'equipment-update-dialog-planner-group'}
                select
                value={plannerGroup}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPlannerGroup(event.target.value ?? '')
                }}
                fullWidth
                label={t('sapPm.label.plannerGroup')}
              >
                <MenuItem value={''}>&nbsp;</MenuItem>
                {plannerGroups?.map((group) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<Close />}
          variant="text"
          onClick={props.onClose}
          data-test-id="sap-pm-cancel-mn"
        >
          {t('action.cancel')}
        </Button>
        {!error && (
          <Button
            onClick={() => onSend()}
            startIcon={<FileUpload />}
            color="primary"
            variant="text"
            disabled={!mergedEvent.equipment}
            sx={getFormPrimaryButtonSx}
            data-test-id="sap-pm-send-mn"
          >
            {t('sapPm.action.createMaintenanceNotification')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
