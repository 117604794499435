import {find, isEqual, isUndefined, keys, omitBy, union} from 'lodash'

import {FilterOptions} from '../common/utils/filterParameter'

export const getFilterDifference = (
  current: FilterOptions,
  next: FilterOptions
): string | undefined => {
  const filteredCurrent = omitBy(current, isUndefined)
  const filteredNext = omitBy(next, isUndefined)
  return find(
    union(keys(filteredCurrent), keys(filteredNext)),
    (key) => !isEqual(filteredCurrent[key], filteredNext[key])
  )
}
