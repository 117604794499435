import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {Repeat as RepeatIcon} from '@mui/icons-material'
import {Button, Grid, Link} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'
import {Link as RouterLink} from 'react-router-dom'

import {
  defaultDesktop,
  desktopTasksFilterOptions,
  DesktopTasksFilterOptionsKeys,
  Page
} from '../common/utils/filterParameter'
import {DoneTasksBox, OngoingTasksBox, PendingTasksBox} from '../containers/shiftEvents/TasksBox'
import {useCancelInactiveQueries} from '../hooks/useCancelInactiveQueries'
import {useConfig} from '../hooks/useConfig'
import {useFilterDatePicker} from '../hooks/useDatePicker'
import {DraggableTaskContextProvider} from '../hooks/useDraggableTask'
import {usePageFilters} from '../hooks/useEventsFilter'
import {useTrackAnalyticsEvent} from '../hooks/useTrackAnalyticsEvents'
import {EventsFilterBar} from '../layouts/FilterBar'
import {routeToRecurringTasks} from '../routes'
import {getActionContentSXWithFilter, getButtonOutlineWhite} from '../styles/common'
import {getFilterDifference} from '../utils/filter.utils'

export const TasksPage: React.FC = () => {
  const [filterOptions, setFilters] = usePageFilters(Page.Tasks)
  const {getDateRange} = useFilterDatePicker()
  const {t} = useTranslation()
  const breakpoint = useWidth()
  const {plantId} = useConfig()
  useCancelInactiveQueries()
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  const [isMobileFilterOpen, openMobileFilter] = useState(false)
  const extendedFilterOptions = {...filterOptions}
  if (!extendedFilterOptions.timeRange) {
    extendedFilterOptions.timeRange = getDateRange()
  }

  const isMobile = breakpoint === 'xs'
  const isSmall = isMobile || breakpoint === 'sm'

  return (
    <FlexPage
      appName="Cockpit"
      title={isMobileFilterOpen ? t('shiftEvent.action.filter') : t('tasks.pageName')}
      sxActionContent={getActionContentSXWithFilter(isMobileFilterOpen)}
      headerActionContent={
        <Grid spacing={1} container alignItems="center" justifyContent="flex-end" wrap="nowrap">
          <Grid item xs={isMobileFilterOpen ? 12 : undefined}>
            <EventsFilterBar<DesktopTasksFilterOptionsKeys>
              activeSettings={filterOptions}
              isCollapsible={isSmall}
              options={desktopTasksFilterOptions}
              onChange={(nextFilterOptions) => {
                setFilters(nextFilterOptions)
                trackAnalyticsEvent('userAdjustsFilter', {
                  filterPage: Page.Tasks,
                  filterName: getFilterDifference(nextFilterOptions, filterOptions)
                })
              }}
              openMobileFilter={openMobileFilter}
              isMobileFilterOpen={isMobileFilterOpen}
              defaultFilters={defaultDesktop}
            />
          </Grid>
          {!isMobileFilterOpen && (
            <Grid item>
              <Link
                onClick={() =>
                  trackAnalyticsEvent('userMovesPage', {
                    pageFrom: t('tasks.pageName', {lng: 'en'}),
                    pageTo: t('recurringTasks.pageName', {lng: 'en'})
                  })
                }
                component={RouterLink}
                sx={{
                  textDecoration: 'none'
                }}
                to={generatePath(routeToRecurringTasks, {plantId})}
              >
                <Button
                  sx={getButtonOutlineWhite}
                  data-test-id="task-page-recurring-tasks-button"
                  variant="text"
                  startIcon={isMobile ? undefined : <RepeatIcon />}
                >
                  {isMobile ? <RepeatIcon /> : t('tasks.recurringTasks')}
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      }
    >
      {!isMobileFilterOpen && (
        <DraggableTaskContextProvider>
          <Grid container spacing={2} sx={{height: {md: 640}}}>
            <Grid item xs={12} md={4} sx={{height: {md: '100%'}}}>
              <PendingTasksBox filterOptions={extendedFilterOptions} />
            </Grid>
            <Grid item xs={12} md={4} sx={{height: {md: '100%'}}}>
              <OngoingTasksBox filterOptions={extendedFilterOptions} />
            </Grid>
            <Grid item xs={12} md={4} sx={{height: {md: '100%'}}}>
              <DoneTasksBox filterOptions={extendedFilterOptions} />
            </Grid>
          </Grid>
        </DraggableTaskContextProvider>
      )}
    </FlexPage>
  )
}
