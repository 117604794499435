import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import React from 'react'

import {useOrderTypes} from '../../hooks'
import {OrderType} from '../../types'
import {SelectAllButton} from '../common/SelectAllButton'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

const getLabel = (code: string, data: OrderType[]) => {
  const orderType = data.find((orderType) => orderType.code === code)
  return orderType ? `${orderType.code} - ${orderType.name}` : code
}

export const WorkOrderTypeFilterDropDown: React.FC<CommonFilterDropDownProps<string>> = (props) => {
  const {data = []} = useOrderTypes()
  const options = data.map(({code}) => code)
  const {selectAll} = props

  return (
    <FilterDropDown
      {...props}
      options={options}
      keyName="orderType"
      customTemplate={(code) => getLabel(code, data)}
      renderValue={(codes) => codes.map((code) => getLabel(code, data)).join(', ')}
      fullWidth={false}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() => selectAll && selectAll(keyName, options)}
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
