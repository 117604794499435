import {EventType} from '@hconnect/common/types'
import {validateAll, ValidatorConfig} from '@hconnect/common/validators'
import {Check, Close} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useTrackAnalyticsEvent} from '../../hooks/useTrackAnalyticsEvents'
import {EventFormState, EventUpdate, ShiftEventAndUpdateEvent} from '../../types'

type EventFormActionsProps = {
  state: EventFormState
  setState: React.Dispatch<React.SetStateAction<EventFormState>>
  doClose: () => void
  parentDoSubmit: (item: EventUpdate) => void
  validatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent>
}

export const EventFormActions: FC<EventFormActionsProps> = ({
  parentDoSubmit,
  state,
  setState,
  doClose,
  validatorConfig
}) => {
  const {t} = useTranslation()
  const trackAnalyticsEvent = useTrackAnalyticsEvent()

  const item = state.item
  const disableSubmit = state.showValidation && state.validationError.size > 0

  const doSubmit = () => {
    const validationError = validateAll<ShiftEventAndUpdateEvent>(validatorConfig, item)

    if (validationError.size === 0) {
      const stoppageCode = 'stoppageCode' in item && item.stoppageCode
      const category = 'category' in item && item.category
      trackAnalyticsEvent('onCategoryStoppageCodeEvent', {
        eventType: item.eventType as EventType,
        ...(stoppageCode && {stoppageCode}),
        ...(category && {category})
      })
      parentDoSubmit(item as EventUpdate)
    } else {
      setState((currentState: EventFormState) => ({
        ...currentState,
        showValidation: true,
        validationError
      }))
    }
  }

  return (
    <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
      <Button
        startIcon={<Close />}
        variant="text"
        onClick={doClose}
        data-test-id="event-form-cancel-button"
      >
        {t('action.cancel')}
      </Button>
      <Button
        startIcon={<Check />}
        disabled={disableSubmit}
        color="primary"
        variant="contained"
        onClick={doSubmit}
        data-test-id="event-save-button"
      >
        {t('action.save')}
      </Button>
    </Box>
  )
}
