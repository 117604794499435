import {
  TEXT_SEARCH_HIGHLIGHT_COLOR,
  TEXT_SEARCH_HIGHLIGHT_FOCUS_COLOR
} from '@hconnect/common/consts'
import {filterSxStyles} from '@hconnect/common/styles'
import {KeyboardArrowUp, KeyboardArrowDown} from '@mui/icons-material'
import {Box, IconButton, InputAdornment, TextField} from '@mui/material'
import {isEmpty, isNumber} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  'data-test-id'?: string
  onChange: (newValue: string | undefined) => void
  value?: string
  showDescriptions?: (show: boolean) => void
}

const MAX_ALLOWED_CHARS = 150

export const navigate = (
  highlightedElementIndex: number | null,
  setHighlightedElementIndex: (index: number | null) => void,
  top?: boolean
) => {
  // elements are fetched here because in the meantime user can modify
  // amount of founded elements by e.g. opening details card
  const highlightedElements = document.querySelectorAll<HTMLElement>('span[data-highlighted]')
  if (!isEmpty(highlightedElements)) {
    if (isNumber(highlightedElementIndex)) {
      if (highlightedElements[highlightedElementIndex]) {
        highlightedElements[highlightedElementIndex].style.backgroundColor =
          TEXT_SEARCH_HIGHLIGHT_COLOR
      }

      let index: number
      if (top) {
        index =
          highlightedElementIndex === 0 || highlightedElementIndex > highlightedElements.length - 1
            ? highlightedElements.length - 1
            : highlightedElementIndex - 1
      } else {
        index =
          highlightedElements.length - 1 <= highlightedElementIndex
            ? 0
            : highlightedElementIndex + 1
      }

      highlightedElements[index].style.backgroundColor = TEXT_SEARCH_HIGHLIGHT_FOCUS_COLOR
      highlightedElements[index].scrollIntoView({block: 'nearest', inline: 'nearest'})
      setHighlightedElementIndex(index)
    } else {
      const index = top ? highlightedElements.length - 1 : 0
      highlightedElements[index].style.backgroundColor = TEXT_SEARCH_HIGHLIGHT_FOCUS_COLOR
      setHighlightedElementIndex(index)
    }
  }
}

export const resetHighlight = (
  highlightedElementIndex: number | null,
  setHighlightedElementIndex: (index: number | null) => void
) => {
  const highlightedElements = document.querySelectorAll<HTMLElement>('span[data-highlighted]')
  if (highlightedElementIndex && highlightedElements[highlightedElementIndex]) {
    highlightedElements[highlightedElementIndex].style.backgroundColor = TEXT_SEARCH_HIGHLIGHT_COLOR
  }
  setHighlightedElementIndex(null)
}

export const FreeTextSearch: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {onChange, value, showDescriptions: showDesc} = props
  const [text, setText] = React.useState<string>(value || '')
  const [highlightedElementIndex, setHighlightedElementIndex] = React.useState<number | null>(null)

  const textRef = React.useRef<HTMLInputElement>()

  return (
    <Box display="flex">
      <TextField
        type="text"
        inputRef={textRef}
        value={text}
        onChange={(e) => setText(e?.target?.value)}
        inputProps={{maxLength: MAX_ALLOWED_CHARS}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={(theme) => ({
                  color: theme.palette.common.white,
                  marginRight: theme.spacing(0.5)
                })}
                onClick={() => {
                  const val = textRef.current?.value
                  if (val) {
                    navigate(highlightedElementIndex, setHighlightedElementIndex, true)
                  }
                }}
                data-test-id="search-free-text-up"
              >
                <KeyboardArrowUp />
              </IconButton>
              <IconButton
                onClick={() => {
                  const val = textRef.current?.value
                  if (val) {
                    navigate(highlightedElementIndex, setHighlightedElementIndex)
                  }
                }}
                data-test-id="search-free-text-down"
              >
                <KeyboardArrowDown />
              </IconButton>
            </InputAdornment>
          )
        }}
        variant="filled"
        label={t('shiftEvent.label.freeText')}
        placeholder={t('pleaseStartTyping')}
        sx={filterSxStyles.textInputField}
        data-test-id={props['data-test-id']}
        onKeyPress={(e) => {
          const val = textRef.current?.value
          if (e.key === 'Enter' && (val || value)) {
            if (val === value) {
              navigate(highlightedElementIndex, setHighlightedElementIndex)
            } else {
              resetHighlight(highlightedElementIndex, setHighlightedElementIndex)
              navigate(highlightedElementIndex, setHighlightedElementIndex)
              onChange(val)
              showDesc && showDesc(true)
            }
          }
        }}
      />
    </Box>
  )
}
