import {ProcessStage} from '@hconnect/common'
import {useProcessStages} from '@hconnect/common/hooks/useProcessStages'
import {filterSxStyles} from '@hconnect/common/styles'
import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material'
import {Autocomplete, Box, MenuItem, Paper, TextField, useTheme} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'

type ProcessStageAutocompleteProps = {
  plantId: string
  onChange: (processStage?: string[]) => void
  value?: string[]
  'data-test-id'?: string
}

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />
const getProcessStageLabel = (stage: ProcessStage) => `${stage.code} - ${stage.name}`

export const ProcessStageFilterAutocomplete: FC<ProcessStageAutocompleteProps> = ({
  onChange,
  value = [],
  plantId,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const [inputValue, setInputValue] = useState<string>('')
  const {data: stages = []} = useProcessStages<ProcessStage[]>(plantId, {
    select: (data) => Array.from(data, ([code, name]) => ({code, name}))
  })
  const selectedProcessStage = stages.filter((stage) => value.includes(stage.code))

  return (
    <Autocomplete
      multiple
      data-test-id={dataTestId}
      options={stages}
      value={selectedProcessStage ?? null}
      disableCloseOnSelect
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          setInputValue('')
          onChange(undefined)
          return
        }
        if (next && typeof next !== 'string') {
          onChange(next.map(({code}) => code))
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newValue, reason) => {
        if (reason !== 'reset') {
          setInputValue(newValue)
        }
      }}
      sx={{
        marginRight: {xs: 2, md: 0}
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth={false}
            data-test-id="events-filter-bar-processStage-search-field"
            sx={mergeSxs(
              {
                '& label': {
                  width: 'calc(100% - 32px)',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }
              },
              filterSxStyles.textInputField
            )}
            variant="filled"
            placeholder={t('pleaseStartTyping')}
            label={
              isEmpty(selectedProcessStage)
                ? t('shiftEvent.label.processStage')
                : t('shiftEvent.label.processStages', {
                    count: selectedProcessStage.length,
                    codes: selectedProcessStage.map(({name}) => name).join(', ')
                  })
            }
          />
        )
      }}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Box
            display={'flex'}
            pl={2.5}
            key={option.code}
            data-test-id={`events-filter-bar-processStage-${option.code}`}
          >
            {value.includes(option.code) ? (
              <CheckBoxIcon style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}} />
            ) : (
              <CheckBoxOutlineBlankIcon
                style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}}
              />
            )}
            <Box flex={1}>{option.name}</Box>
          </Box>
        </MenuItem>
      )}
      getOptionLabel={getProcessStageLabel}
      forcePopupIcon={false}
      PaperComponent={paper}
      renderTags={() => ''}
    />
  )
}
