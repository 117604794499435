import {Shift} from '@hconnect/common/types'
import {timezonedDateValidator, getShift} from '@hconnect/common/utils'
import {Moment} from 'moment-timezone'

export function isSingleShiftRange(startDate: Moment, endDate: Moment, allShifts: Shift[]) {
  timezonedDateValidator(startDate, endDate)
  const startingShift = getShift(startDate, allShifts)
  const endingShift = getShift(endDate, allShifts)
  return startingShift.startDate.isSame(endingShift.startDate)
}
