import {filterSxStyles} from '@hconnect/common/styles'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {Box, MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface CommonFilterDropDownProps<T extends string | number> {
  'data-test-id'?: string
  id?: string
  onChange: (newValue: T[] | undefined) => void
  customTemplate?: (key: T) => React.ReactNode
  renderValue?: (args: T[]) => React.ReactNode
  value?: T[]
  customDropDownFooter?: (key: string) => React.ReactNode
  fullWidth?: boolean
  removeFilter?: (filter: string) => void
  selectAll?: (filter: string, options: T[]) => void
  isCollapsible?: boolean
  multiple?: boolean
}
export interface FilterDropDownProps<T extends string | number>
  extends CommonFilterDropDownProps<T> {
  keyName: string
  options: ReadonlyArray<T>
  disabledValues?: ReadonlyArray<T>
}

export function FilterDropDown<T extends string | number>(
  props: FilterDropDownProps<T>
): ReturnType<React.FC> {
  const {
    keyName,
    options,
    customTemplate,
    renderValue,
    value,
    customDropDownFooter,
    disabledValues = []
  } = props

  const {t} = useTranslation()

  const renderOverride: (args: T[]) => React.ReactNode =
    renderValue ??
    ((args: T[]) => args.map((arg) => t(`shiftEvent.${keyName}.${arg.toString()}`)).join(', '))

  const {fullWidth = true} = props
  return (
    <Box display="flex">
      <TextField
        variant="filled"
        sx={filterSxStyles.textInputField}
        data-test-id={props['data-test-id']}
        id={props.id}
        fullWidth={fullWidth}
        label={t(`shiftEvent.label.${keyName}`)}
        required={false}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const val = event.target.value as unknown as T[] | undefined
          if (val?.length === 0) props.onChange(undefined)
          else props.onChange(val)
        }}
        value={value ?? []}
        SelectProps={{
          multiple: true,
          renderValue: renderOverride as (args: unknown) => React.ReactNode
        }}
        select
      >
        {options.map((option) => (
          <MenuItem
            data-test-id={`${props['data-test-id']}-${option}`}
            key={option}
            value={option}
            disabled={disabledValues.includes(option)}
          >
            {(value ?? []).includes(option) ? (
              <CheckBoxIcon sx={{mr: 1}} />
            ) : (
              <CheckBoxOutlineBlankIcon sx={{mr: 1}} />
            )}

            {customTemplate ? customTemplate(option) : t(`shiftEvent.${keyName}.${option}`)}
          </MenuItem>
        ))}
        {customDropDownFooter ? customDropDownFooter(keyName) : null}
      </TextField>
    </Box>
  )
}
