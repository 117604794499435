import {useEndOfPageScrollDebounce} from '@hconnect/common/hooks/useListScrollDebounce'
import {getQuickSelectFilter} from '@hconnect/common/utils'
import {PaginationOptions} from '@hconnect/uikit'
import {Theme, useMediaQuery} from '@mui/material'
import {useEffect, useState} from 'react'

import {
  AllFilterOptions,
  CombinedWorkOrdersFilterOptions,
  FilterOptions,
  Page
} from '../common/utils/filterParameter'
import {SortOrder, WorkOrderSortBy} from '../types/shiftHandover.types'
import {PaginationParams, WorkOrdersPayload} from '../types/workOrders.types'

import {useConfig} from './useConfig'
import {usePageFilters} from './useEventsFilter'
import {useOnPageWithoutUnmountFocus} from './useOnPageWithoutUnmountFocus'

export const MOBILE_ELEMENTS_PER_PAGE = 50

type UsePagedDataProps<T> = {
  fetchData: (params: WorkOrdersPayload<CombinedWorkOrdersFilterOptions>) => {
    data: PaginationParams<T> | undefined
    isLoading: boolean
    isFetching: boolean
    refetch: () => void
  }
  pageType: Page
}

type PagedDataResult<T> = {
  data: PaginationParams<T> | undefined
  isLoading: boolean
  filterOptions: FilterOptions
  setFilters: (filters: FilterOptions) => void
  changePage: (pageNumber: number) => void
  setItemsPerPage: (itemsPerPage: number) => void
  paginationOptions: PaginationOptions
  shouldLoadNextPage: boolean
}

export function usePagedData<T>({fetchData, pageType}: UsePagedDataProps<T>): PagedDataResult<T> {
  const config = useConfig()

  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(50)
  const [filterOptions, setFilters] = usePageFilters(
    pageType,
    pageType === Page.WorkOrders
      ? {
          sortWorkOrderBy: WorkOrderSortBy.Status,
          woSortDir: SortOrder.Descending
        }
      : undefined
  )

  const extendedFilterOptions: AllFilterOptions = {...filterOptions}
  if (!extendedFilterOptions.timeRange) {
    const [startDate, endDate] = getQuickSelectFilter(
      config.defaultTimeRange,
      config.shifts,
      config.plantNow()
    )

    extendedFilterOptions.timeRange = {
      startDate: startDate,
      endDate: endDate
    }
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const {data, isLoading, isFetching, refetch} = fetchData({
    input: extendedFilterOptions,
    offset: pageNumber * itemsPerPage,
    limit: itemsPerPage
  })

  useOnPageWithoutUnmountFocus(refetch)

  const shouldLoadNextPage = itemsPerPage < (data?.total || 0)

  const {trackScrolling} = useEndOfPageScrollDebounce(() => {
    setItemsPerPage((items) => items + MOBILE_ELEMENTS_PER_PAGE)
  }, shouldLoadNextPage)

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', trackScrolling)
    }

    return () => document.removeEventListener('scroll', trackScrolling)
  }, [isMobile, trackScrolling])

  useEffect(() => {
    if (isMobile) {
      setItemsPerPage(MOBILE_ELEMENTS_PER_PAGE)
      changePage(0)
    }
  }, [isMobile])

  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }

  return {
    data,
    isLoading: isLoading || isFetching,
    filterOptions,
    setFilters,
    changePage,
    setItemsPerPage,
    paginationOptions: {
      page: pageNumber,
      rowsPerPage: itemsPerPage,
      onPaginationChange,
      totalDataLength: data?.total ?? 0
    },
    shouldLoadNextPage
  }
}
