import {customThemeConstants, mergeSx} from '@hconnect/uikit/src/lib2'
import {Theme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'

export const getHighlightedEventSx = (selected?: boolean) => ({
  backgroundColor: selected ? 'rgba(218, 9, 1, 0.12)' : 'rgba(218, 9, 1, 0.04)',
  borderLeftStyle: 'solid',
  borderLeftWidth: 4,
  borderLeftColor: 'error.light',
  '&:hover': {
    backgroundColor: 'rgba(218, 9, 1, 0.08)'
  }
})
export const getHighlightedEventCellSx = () => ({
  '& .MuiTableCell-body:first-of-type:before': {
    content: "''",
    width: 4,
    height: '100%',
    backgroundColor: 'error.light',
    position: 'absolute',
    left: 0,
    top: 0
  },
  '& .MuiTableCell-body': {
    position: 'relative'
  }
})

export const markEvent: SystemStyleObject<Theme> = {
  backgroundColor: 'grey.100'
}

export const getButtonOutlineWhite = (theme: Theme) => ({
  border: 1,
  borderColor: customThemeConstants().palette.mediumInputBorder,
  color: theme.palette.common.white,
  padding: 2
})

export const hideMobileContainerWithFloatingContentSx = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    minHeight: 0
  }
})

export const getActionContentSXWithFilter = (isMobileFilterOpen: boolean) =>
  mergeSx(isMobileFilterOpen ? {width: '100%'} : {}, hideMobileContainerWithFloatingContentSx)
